/* eslint-disable @typescript-eslint/no-unused-expressions */
import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MdbModalRef } from "mdb-angular-ui-kit/modal";

import { AppUtilityService } from "src/app/app-utility.service";
import { ClientShowModel } from "src/app/core/models/client-show.model";
import { LoanDocModel } from 'src/app/core/models/loanDoc.model';
import { ClientDetailsService } from 'src/app/core/rest-services/client-details.service';
import { QuoteStoreService } from 'src/app/core/rest-services/quote-store.service';
import { LMSDialogHandlerService } from 'src/app/shared/lmsdialog-handler.service';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { PageCardheadComponent } from 'src/app/shared/ui/pageheader-card/pageheader-card.component';
import { ClientHeaderComponent } from '../client-header/client-header.component';
import { LmsPaginateComponent } from '@lmsShared';
import { NgxPaginationModule, PaginationInstance } from 'ngx-pagination';
import { FinbondFileModel } from '@lmsModels';

@Component({
  selector: 'app-client-documents',
  templateUrl: './client-documents.component.html',
  styleUrls: ['./client-documents.component.css'],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    PageCardheadComponent,
    ClientHeaderComponent,
    NgxPaginationModule,
    LmsPaginateComponent,
    //LMSSharedUiModule
  ],
  standalone: true,
})
export class ClientDocumentsComponent implements OnInit, AfterViewInit {
  @ViewChild(LmsPaginateComponent)
  set lmspaginate(v: LmsPaginateComponent) {
    this._lmsPaginate = v;
  }
  _lmsPaginate: LmsPaginateComponent;

  //PAGINATOR CODE
  config: PaginationInstance = {
    id: 'pager1',
    itemsPerPage: 10,
    currentPage: 1
  };

  lstDocuments: any[] = [];
  quoteId: string;
  loanRef: string;
  loanDoc: LoanDocModel = new LoanDocModel();
  curr: any;
  downloadObject: any = {};
  finalFileName: any;
  downloadResults: any = {};
  isReadOnly = false;
  isDocumentLoading = false;
  clientShow: ClientShowModel;
  //PAGINATOR CODE
  // @Input() items: Array<any>;
  // @Output() changePage = new EventEmitter<any>(true);
  // @Input() initialPage = 1;
  // @Input() pageSize = 15;
  // @Input() maxPages = 15;

  // pager: any = {};

  constructor(
    public quoteStoreService: QuoteStoreService,
    public global_utility: AppUtilityService,
    public lmsDialogHandler: LMSDialogHandlerService,
    public dialogRef: MdbModalRef<ClientDocumentsComponent>,
    private clientDetailsService: ClientDetailsService,
  ) {}

  ngOnInit(): void {
    // if(this.readOnly && this.loanRef) {
    //   this.listDocsByLoanRef();
    //   return;
    // }
    this.isDocumentLoading = true;
  }

  ngAfterViewInit(): void {
    this.listClientDocs();
  }

  // listDocsByLoanRef() {
  //   if(this.readOnly && this.loanRef){
  //     this.loanDoc.quoteId = '';
  //     this.loanDoc.loanReference = this.loanRef;
  //   }
  //   this.clientDetailsService.listClientDocsByLoanReference(this.loanDoc)
  //   .subscribe((docList: any) => {
  //       this.lstDocuments = docList;
  //   });
  // }

  listClientDocs() {
    console.log('listClientDocs ' + this.quoteId);
    this.loanDoc.quoteId = `Q${this.quoteId}`;

    this.clientDetailsService.listClientDocs(this.loanDoc).subscribe({
      next: (res: any) => {
        this.lstDocuments = res;
        this.isDocumentLoading = false;
      },
      //     for (let doc of this.lstDocuments) {
      //       doc.documentType = doc.documentType.replaceAll("_", " ");
      //     }

      //     this.items = this.lstDocuments;
      //     if (this.items && this.items.length) {
      //       this.setPage(this.initialPage);
      //     }

      //     this.getDocChecklist();
      //     this.spinDialogRef.close();
    });
  }

  onDownloadPdf(data: any) {
    this.curr = JSON.stringify(data);
    this.finalFileName = this.curr.split('"').join('');
    console.log('Curr ' + this.finalFileName);

    (this.downloadObject.fileName = this.finalFileName),
    (this.downloadObject.bucketName = ''),
    (this.downloadObject.userName = ''),
    (this.downloadObject.originalFileName = '');

    this.clientDetailsService
      .download(this.downloadObject)
      .subscribe((res: any) => {
        if (res && res.errorType == 'DOCUMENT_DOWNLOAD_ERROR') {
          this.global_utility.handle_returnMessage(res.message);
          return;
        }

        this.downloadResults = res;
        const base64String = this.downloadResults.documentContent;

        this.openPdfViewer(
          base64String,
          this.downloadResults.originalFilename,
          this.downloadResults.contentType,
        );
      });
  }

  openPdfViewer(base64String: any, fileName: string, contentType: string) {
    const source = `data:` + contentType + `;base64,` + `${base64String}`;
    this.lmsDialogHandler.openDocumentViewer(source, fileName);
  }

  // downloadPdf(base64String: any, fileName: string, contentType: string) {
  //   const source = `data:` + contentType + `;base64,` + `${base64String}`;
  //   const link = document.createElement("a");
  //   link.href = source;
  //   link.download = fileName;
  //   link.click();
  //   //this.finalFileName =null;

  // }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    // this.fileName = file.name;
    const finbondFile = new FinbondFileModel();
    finbondFile.fileName = file.name;
    finbondFile.contentType = file.type;

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (!reader.result) {
        // eslint-disable-line @typescript-eslint/strict-boolean-expressions
        this.global_utility.warning('Content of the selected file is invalid');
        return;
      }

      finbondFile.fileContents = reader.result!.toString().split(',')[1];
      // this.loanDoc.file = finbondFile;
      this.loanDoc.contentType = finbondFile.contentType;
      this.loanDoc.originalFileName = finbondFile.fileName;
      this.loanDoc.documentContents = finbondFile.fileContents;
    };
  }

  onDocumentSubmit() {
    if (!this.loanDoc.originalFileName) {
      this.loanDoc.documentName = `${this.quoteId}_Loan Summary(unsigned).pdf`;
      this.loanDoc.docId = 0;
      this.loanDoc.documentType = 'LOAN_SUMMARY';
      //this.loanDoc.docCheckListId = value.id;

      const docType = this.loanDoc.documentType.replace('_', ' ');
      this.global_utility.info(`Please select the ${docType} document you have scanned and saved on you pc`, 'Document list');
      return;
    }

    this.global_utility.startLoading('Submitting document.');
    // this.isLoading = true;
    this.loanDoc.idNumber = this.quoteStoreService.quoteWorkbook.idNumber;
    //clientDetails.idNumber;
    this.loanDoc.customerId = this.quoteStoreService.quoteWorkbook.clientNumber;
    // this.clientDetails.clientNumber;
    this.loanDoc.loanId = `Q${this.quoteStoreService.quoteWorkbook.id}`;
    this.loanDoc.userName = this.global_utility.getGlobalData('userDisplay');
    this.loanDoc.applicationId = this.quoteStoreService.quoteWorkbook.id;
    this.loanDoc.docCheckListId = this.loanDoc.docId;

    this.clientDetailsService.uploadDocument(this.loanDoc).subscribe((res) => {
      if (res) {
        this.lstDocuments = res;

        for (const doc of this.lstDocuments) {
          doc.documentType = doc.documentType.replaceAll('_', ' ');
        }

        this.global_utility.stopLoading();
        this.loanDoc = {} as LoanDocModel;
      }
    });
  }

  covertDate(date: any) {
    if (date != null) {
      // eslint-disable-next-line no-var
      let str = date;
      str = str.substring(0, 10);
      date = str;
      return date;
    } else {
      return date;
    }
  }

  close() {
    this.dialogRef.close();
  }
}
