import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AppUtilityService } from 'src/app/app-utility.service';
import { LMSDialogHandlerService } from '../../lmsdialog-handler.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxPaginationModule, PaginationInstance } from 'ngx-pagination';
import { ZARcurrencyPipe } from '@lmsSharedPipes';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { LmsPaginateComponent } from '@lmsShared';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-branch-stats-report',
  templateUrl: './branch-stats-report.component.html',
  styleUrls: ['./branch-stats-report.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ZARcurrencyPipe,
    NgxPaginationModule,
    LmsPaginateComponent
  ],
})
export class BranchStatsReportComponent {

  lstStats: Array<any> = [];
  title = 'export-excel';
  fileName = '';
  @ViewChild('TABLE') table: ElementRef;
  //displayedColumns = ['position', 'name', 'weight', 'symbol'];
  dataSource = this.lstStats;
  config: PaginationInstance = {
    id: 'pager',
    itemsPerPage: 15,
    currentPage: 1,
  };
  
  constructor(
    // private route: ActivatedRoute,
    // private clientSearch: ClientDetailsService,
    // public quoteStoreService: QuoteStoreService,
    public lmsDialogHandler: LMSDialogHandlerService,
    public global_utility: AppUtilityService,
    public dialogRef: MatDialogRef<BranchStatsReportComponent>,
    private formbuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) data: any
  ) { 
    this.lstStats = data.statsList;
  }

  exportLoansToExcel(): void {
    this.title = 'export-excel';
    this.fileName = 'Branch Report: ' + new Date() + '.xlsx';

    /* pass here the table id */
    //const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.lstQuotes);
    // table_to_sheet(this.lstQuotes);
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      this.table.nativeElement,
    );

    /* generate workbook and add the worksheet */

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */

    XLSX.writeFile(wb, this.fileName);
  }

  close() {
    this.dialogRef!.close();
    //return this.dialogRef.close(this.employmentDetailsForm.value);
  }

}
