/* eslint-disable no-inner-declarations */
/* eslint-disable no-var */
/* eslint-disable @typescript-eslint/no-namespace */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { CommonModule } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FingerNameEnum } from '@lmsConstants';
import { BiometricsModel } from '@lmsModels';
import { QuoteStoreService } from '@lmsrestServices';
import { PageCardheadComponent } from '@lmsShared';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { Subscription } from 'rxjs';
import { AppUtilityService } from 'src/app/app-utility.service';
import { PreAuthorisationModel } from 'src/app/core/models/pre-authorisation.model';
import { BiometricsSerivce } from 'src/app/core/rest-services/biometrics.service';
import { UserService } from 'src/app/core/services/lmsuser.service';

declare let Fingerprint: any;

export interface CustomerEnrollmentEntity {
  id?: number;
  idNumber?: string;
  username?: string;
  fingerName?: FingerNameEnum;
  fmd?: string;
  created?: string;
  enrolledBy?: string;
  disabledAt?: string;
  disabledBy?: string;
}

// export namespace CustomerEnrollmentEntity {
//   const FingerNameEnum = {
//     LeftPinky: 'LEFT_PINKY' as FingerNameEnum,
//     LeftRing: 'LEFT_RING' as FingerNameEnum,
//     LeftMiddle: 'LEFT_MIDDLE' as FingerNameEnum,
//     LeftIndex: 'LEFT_INDEX' as FingerNameEnum,
//     LeftThumb: 'LEFT_THUMB' as FingerNameEnum,
//     RightPinky: 'RIGHT_PINKY' as FingerNameEnum,
//     RightRing: 'RIGHT_RING' as FingerNameEnum,
//     RightMiddle: 'RIGHT_MIDDLE' as FingerNameEnum,
//     RightIndex: 'RIGHT_INDEX' as FingerNameEnum,
//     RightThumb: 'RIGHT_THUMB' as FingerNameEnum
//   };
// }

const NO_FINGER = 'NO_FINGER';

export interface SelectItem<T = any> {
  label?: string;
  value: T;
  styleClass?: string;
  icon?: string;
  title?: string;
  disabled?: boolean;
}

@Component({
  selector: 'app-branch-manager-override',
  templateUrl: './branch-manager-override.component.html',
  styleUrls: ['./branch-manager-override.component.css'],
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, PageCardheadComponent]
})
export class BranchManagerOverrideComponent implements OnInit, OnDestroy {
  biometricsModel: BiometricsModel = new BiometricsModel();

  deviceInfo = false;

  readQuality: string;
  status: string;
  sdk: any;
  numberReadings: number;
  deviceSerial: string;
  currentFormat: any;
  deviceID: string;
  deviceUIDType: string;
  deviceModality: string;
  deviceTechnology: string;
  sampleData: string[];
  radioFormat: number;
  stopAcquisitionDisabled: boolean;
  startAcquisitionDisabled: boolean;

  enrollmentError?: string;
  enrollmentMessage?: string;
  selectedFingerName: any;
  verifyDisabled: boolean;

  lmsUserProfile: any;
  continueApplicationDisabled: boolean;
  sampleDataVerify: string;
  acquisitionStarted = false;
  subscriptions: Subscription[] = [];
  constructor(
    public quoteStoreService: QuoteStoreService,
    private userService: UserService,
    private biometricsService: BiometricsSerivce,
    private global_utility: AppUtilityService,
    private modalRef: MdbModalRef<BranchManagerOverrideComponent>){

    this.readQuality = '';
    this.status = 'Select a device';
    this.sampleData = [];
    this.radioFormat = 2;
    this.stopAcquisitionDisabled = true;
    this.startAcquisitionDisabled = false;
    this.continueApplicationDisabled = true;

    this.enrollmentMessage = "";
    this.enrollmentError = "";
    this.selectedFingerName = "";
    this.verifyDisabled = true;
  }

  ngOnInit(): void {

    this.selectedFingerName = '';
    let sub1 = this.userService.LMSProfile$.subscribe((lmsuser: any) => {
      if (lmsuser && lmsuser.id!) {
        this.lmsUserProfile = lmsuser;
        console.log("User Profile: ", this.lmsUserProfile);
      }
    });
    this.subscriptions.push(sub1);

    sub1 = this.biometricsService.sampleData$.subscribe((sample) =>{
      if(sample.length > 0 && this.acquisitionStarted){
        this.sampleData = sample;
        this.verifyBranchManager();}

    });
    this.subscriptions.push(sub1);
    sub1 = this.biometricsService.readQuality$.subscribe((quality) =>{
      this.readQuality = quality;
    });
    this.subscriptions.push(sub1);
    sub1 = this.biometricsService.status$.subscribe((status) =>{
      this.showMessage(status);
    });
    this.subscriptions.push(sub1);
  }

  verifyBranchManager() {
    if((this.biometricsModel.bmUserName === '' || this.biometricsModel.bmUserName === null) ||
    (this.biometricsModel.bmUserName === this.userService.userLoginName && this.userService.userJobTitle.jobTitle === 'Branch Consultant')) {
      this.global_utility.warning('Please Enter a valid Branch Manger Username.', '');
      this.biometricsService.resetReadings();
      this.stopUserCapture();
      return;
    }

    if (!this.sampleData || this.sampleData.length == 0) {
      this.global_utility.warning('Finger could not be Verified or no finger provided.', '');
      this.biometricsService.resetReadings();
      return;
    }

    this.sampleDataVerify = this.sampleData.slice(0, 1).toString();
    this.biometricsModel.fingerImage = this.sampleDataVerify;
    this.biometricsModel.fingerName = this.selectedFingerName;
    this.biometricsModel.verifiedBy = this.biometricsModel.bmUserName;
    this.biometricsModel.userName = this.biometricsModel.bmUserName;
    this.biometricsModel.branchName = this.userService.userBranchName;
    this.biometricsModel.skipReason = 'No OTP Received, BM Override';

    this.biometricsService.verifyUserFinger(this.biometricsModel).subscribe(response => {
      console.log(response);
      this.enrollmentError = response?.errorCode;
      this.enrollmentMessage = response?.errorMessage;
      // this.enrollDisabled = true;
      this.numberReadings = 0;
      this.sampleData = [];

      const toastString = 'Verify Finger Response: ' + this.enrollmentMessage;
      if (this.enrollmentError == 'FAILURE') {
        this.global_utility.warning(toastString, '');
      }
      else {
        this.global_utility.info(this.enrollmentMessage,toastString);
        this.modalRef?.close(true);
      }
    });
  }

  showMessage(message){
    //const state = document.getElementById('content-capture');
    //var _instance = this;
    var statusWindow = document.getElementById("status");
    if(statusWindow) {
      statusWindow.innerHTML = message;
    }
  }
  getDevice() {
    Promise.all([
      this.sdk.enumerateDevices()
    ]).then(devices => {
      // @ts-ignore
      this.deviceSerial = devices[0];
      console.log("deviceSerial =" + this.deviceSerial);
    })
      .catch((error) => {
        console.log(error);
      });
  }

  getDeviceInfo() {
    // @ts-ignore
    var deviceInfoPromise: Promise<DeviceInfo> = this.sdk.getDeviceInfo(this.deviceSerial);
    this.deviceInfo = true;
    deviceInfoPromise.then((successObj) => {
      console.log(successObj);
      this.deviceID = successObj.DeviceID;
      this.deviceTechnology = Fingerprint.DeviceTechnology[successObj.eDeviceTech];
      this.deviceUIDType = Fingerprint.DeviceUidType[successObj.eUidType];
      this.deviceModality = Fingerprint.DeviceModality[successObj.eDeviceModality];
    })
      .catch((error) => {
        console.log(error);
      });
  }

  startCapture() {
    if(!this.biometricsModel.bmUserName){
      return;
    }

    if(this.acquisitionStarted)
    {
      console.debug('acquisitionStarted Already true, breaking!');
      return;
    }

    this.startAcquisitionDisabled = true;
    this.stopAcquisitionDisabled = false;
    this.continueApplicationDisabled = true; //this.clientEnrolled.length != 4;

    this.acquisitionStarted = true;
    this.biometricsService.startAcquisition(true)
      .catch((error: any) => {
        this.acquisitionStarted = false;
        console.error('Error starting capture >>', error);
        this.showMessage(error);
      });
  }


  stopUserCapture() {
    this.startAcquisitionDisabled = false;
    this.stopAcquisitionDisabled = true;

    this.acquisitionStarted = false;
    this.biometricsService.stopAcquisition();
  }

  close() {
    this.modalRef.close();
  }

  closeScanFingers() {
    this.showMessage('Closing..please wait')
    this.biometricsService.stopAcquisition().catch((error: any) => {
      console.error(error.message);
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(x => {
      x.unsubscribe();
    })
    this.closeScanFingers();
  }
}
