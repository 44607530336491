<div class="modal-content">
  <div class="modal-header">
    <div class="modal-title center-fields">
      <app-page-cardhead style="margin-top: -1.1rem;" [PageName]="'OTP Override'"
        [PageHeadWidth]=500></app-page-cardhead>
    </div>
  </div>

  <div class="modal-body">
    <div id="content-capture">
      <div id="scores" class="scores center-section py-2">
        <span>
          <h6>Scan Quality</h6>
          <input class="form-control fs-5 text-center" type="text" id="qualityInputBox" readonly [ngModel]="readQuality"
            style="background-color:#DCDCDC;">
        </span>
      </div>
      <div id="status"></div>
      <!-- <div id="imagediv"></div> -->
    </div>

    <div class="my-2">
      <div *ngFor="let data of sampleData; let sampleDataIndex = index;" class="mx-2">
        <label class="mt-2" [for]="'sampleData' + sampleDataIndex">Finger Data {{ sampleDataIndex + 1 }}
        </label>
        <label sampleDataIndex>: Captured success</label>
      </div>
      <div class="center-section">
        <table class="table table-striped table-hover table-sm my-3 mx-2" style="width: 90%;">
          <tbody class="field-4column-label">
            <tr>
              <td>Branch Manager Username:
              </td>
              <td>
                <span>
                  <input placeholder="BM Username" [(ngModel)]="biometricsModel.bmUserName" required>
                  <!-- <button class="btn btn-primary" type="submit" [disabled]="!startAcquisitionDisabled"
                      (click)="verifyBranchManager()"> Verify Biometrics
                    </button> -->
                </span>
                <div>
                  <small class="text-danger"> Please enter Branch manager. </small>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                Verify Finger Response:
              </td>
              <td>
                {{enrollmentMessage}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="flex-container readerDivButtons mt-2">
    <button class="btn btn-primary btn-sm" type="button"
      [disabled]="startAcquisitionDisabled && !biometricsModel.bmUserName" (click)="startCapture()">Start</button>
    <button class="btn btn-primary btn-sm ms-3" type="button" (click)="close()">
      Close
    </button>
  </div>
</div>