import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EmploymentDialogComponent } from '../employment-dialog/employment-dialog.component';

import { BasicEmployersSearchComponent } from "src/app/features/branch-employers/basic-employer-form/basic-employer-form.component";
import { UpdateEmployerComponent } from '../features/branch-employers/update-employer/update-employer.component';
import { AppUtilityService } from "../app-utility.service";
import { MdbRippleModule } from 'mdb-angular-ui-kit/ripple';
import { CommonModule } from '@angular/common';
import { PageCardheadComponent } from '../shared/ui/pageheader-card/pageheader-card.component';
import { AddressModel, ClientShowModel, EmploymentDetailsModel } from '@lmsModels';
import { ClientDetailsService } from '@lmsrestServices';
import { BranchEmployerService } from '../core/services/branch-employer.service';
import { EmployerDetailsModel } from '../features/branch-employers/models/employer-details-model';
import { ClientHeaderComponent } from '../modules/client-header/client-header.component';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';

@Component({
  selector: 'app-employment-details',
  templateUrl: './employment-details.component.html',
  styleUrls: ['./employment-details.component.css'],
  standalone: true,
  imports: [CommonModule, MdbRippleModule, FormsModule, ReactiveFormsModule, PageCardheadComponent, ClientHeaderComponent]
})

export class EmploymentDetailsComponent implements OnInit, AfterViewInit {
  @ViewChild(BasicEmployersSearchComponent) employerBasicSearchComponent: BasicEmployersSearchComponent;

  employmentDetailsModel = new EmploymentDetailsModel();
  employmentDetailsForm = this.formBuilder.group({
    employeeNumber: ['', [Validators.required]],
    department: [''],
    occupation: ['', [Validators.required]],
    placement: [''],
    lineManagerFirstName: ['', [Validators.required]],
    lineManagerLastName: ['', [Validators.required]],
    lineManagerEmail: [''],
    lineManagerContactNumber: ['', [Validators.required]],
    workAdressLine1: [''],
    workAdressLine2: [''],
    workAdressSuburb: [''],
    workAdressTown: [''],
    workAdressAreaCode: ['']
  });

  clientShowModel = {} as ClientShowModel;
  addressModel: AddressModel;
  employment: any;
  hasEmployment: boolean;
  private _partInfo = false;
  refreshEmployment = false;
  hasWorkaddress = false;
  // employmentDetails : any;
  submitted = false;
  employmentOtpForm: FormGroup;
  //sendDisabled: boolean;
  confirmEmployment: boolean;
  addresses: any;
  mustConfirm: boolean;
  isValidDate: boolean;

  startDate = new Date(Date.now());
  endDate = new Date(Date.now());
  employStartDate = new Date(Date.now());
  employEndDate = new Date(Date.now());

  lstEmployers: any[] = [];
  foundEmployer: boolean;
  //employerNametxt = '';
  quoteEmployName: string;

  isReadOnly = false;
  employmentConfirmed = false;
  clientStatus: string;
  employer: any;

  constructor(
    private clientDetailsService: ClientDetailsService,
    private branchEmployerService: BranchEmployerService,
    private formBuilder: FormBuilder,
    public global_utility: AppUtilityService,
    private modalservice: MdbModalService,
    public modalRef: MdbModalRef<EmploymentDetailsComponent>) { }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    this.employment.employerName = this.quoteEmployName;
    this.employmentDetailsForm.patchValue({
      employeeNumber: this.employmentDetailsModel.employeeNumber,
      department: this.employmentDetailsModel.department,
      occupation: this.employmentDetailsModel.occupation,
      placement: this.employmentDetailsModel.placement,
      lineManagerFirstName: this.employmentDetailsModel.lineManagerFirstName,
      lineManagerLastName: this.employmentDetailsModel.lineManagerLastName,
      lineManagerEmail: this.employmentDetailsModel.lineManagerEmail,
      lineManagerContactNumber: this.employmentDetailsModel.lineManagerContactNumber,
      workAdressLine1: this.employmentDetailsModel.workAdressLine1,
      workAdressLine2: this.employmentDetailsModel.workAdressLine2,
      workAdressSuburb: this.employmentDetailsModel.workAdressSuburb,
      workAdressTown: this.employmentDetailsModel.workAdressTown,
      workAdressAreaCode: this.employmentDetailsModel.workAdressAreaCode
    }, {onlySelf: true, emitEvent: true});
    this.hasEmployment = true;
    this.clientStatus = this.clientShowModel.clientStatus!;


    this.validateEmployeeDetails();

    this.employmentOtpForm = this.formBuilder.group({
      'employmentOtp': [this.employmentDetailsModel.employmentOtp],
    }); //??

    if (this.employmentDetailsModel.confirmed) {
      this.employmentConfirmed = true;
      this.hasEmployment = true;
    }

    console.log("Employment details -----", this.employmentDetailsModel);
  }

  // findEmployerByName() {
  //   this.employmentDetailsModel.branchSerial = this.clientDetails.branchSerial;
  //   this.employmentDetailsModel.employerName = this.employment.employerName.toUpperCase();

  //   this.branchEmployerService.findEmployersByName(this.employmentDetailsModel)
  //   .subscribe((lstEmp: any) => {
  //     this.lstEmployers = lstEmp;
  //     if(this.lstEmployers.length > 0){
  //       this.foundEmployer = true;
  //     }
  //   });
  // }

  findEmployerByTNR(empTNR?: string) {
    //const emplDlg = this.loaderService.startLoading('Searcing employment..');

    //this.employment.branchSerial = this.clientDetails.branchSerial;
    //this.employment.employerName = this.employment.employerName.toUpperCase();
    const employersSearch = { employerTNR: empTNR || this.employment.employerTNR, branchSerial: this.employment.branchSerial };
    console.log(employersSearch, 'employer Search');
    return new Promise<boolean>((resolve, reject) => {
      this.branchEmployerService.findEmployerBySerialTNR(employersSearch)
        .subscribe({
          next: (employerList: any) => {
            //hasEmployment will enable the dropdown on Edit
            //this.hasEmployment = false;
            if (employerList && employerList?.id != 0) {
              this.branchEmployerService.setquoteEmployerObs(employerList);
              this.lstEmployers = employerList;
              this.employer = employerList;
              this.foundEmployer = (employerList && employerList.id > 0);
              // this.employmentDetailsModel = employerList;
              resolve(this.foundEmployer);
            }
            else {
              resolve(false);
            }
          },
          error: (err) => {
            resolve(false);
            reject(err);
          }
        });
    });
  }

  close() {
    this.modalRef!.close();
    //return this.dialogRef.close(this.employmentDetailsForm.value);
  }

  saveEmployeeDetails() {
    this.submitted = true;
    if (this.employmentDetailsForm.invalid) {
      return;
    }

    this.mustConfirm = this.employmentDetailsForm.dirty && this.employmentDetailsForm.touched;
    this.confirmEmployment = !this.mustConfirm;

    if (this.employmentDetailsModel.employerName.toUpperCase() == 'SASSA') {
      this.employmentDetailsModel.confirmed = true;
      this.confirmEmployment = true;
      this.doSaveEmploymentDetails();
      return;
    }

    this.modalservice.open(EmploymentDialogComponent, {
      backdrop: true, ignoreBackdropClick: true, keyboard: true, nonInvasive: false, animation: true,
      modalClass: 'modal-md modal-dialog'
    }).onClose.subscribe(result => {
      if (result) {
        this.confirmEmployment = result && result.empConfirmed;

        this.employmentDetailsModel.confirmed = this.confirmEmployment;
        this.employmentDetailsModel.confirmedByUser = this.global_utility.getGlobalData('userDisplay');

        this.doSaveEmploymentDetails();
      }
    });
  }

  private doSaveEmploymentDetails() {
    this.global_utility.startLoading('Saving employment');

    this.employmentDetailsModel.confirmed = this.confirmEmployment;
    this.employmentDetailsModel.confirmedByUser = this.global_utility.getGlobalData('userDisplay');

    this.employmentDetailsModel.userName = this.global_utility.getGlobalData('userDisplay');
    this.employmentDetailsModel.branchName = this.global_utility.getGlobalData('branch_Name');
    this.employmentDetailsModel.branch = this.global_utility.getGlobalData('branch_Name');
    this.employmentDetailsModel.clientNumber = Number(this.clientShowModel.clientNumber!) || 0;
    //this.employmentDetailsModel.idNumber = this.clientDetails.idNumber;
    this.employmentDetailsModel.employerName = this.employmentDetailsModel.employerName.toUpperCase();
    this.employmentDetailsModel.name = this.employmentDetailsModel.employerName.toUpperCase();

    setTimeout(() => {
      let savingemployment = true;
      this.clientDetailsService.saveEmploymentDetails(this.employmentDetailsModel).subscribe({
        next: (res: any) => {
          savingemployment = false;
          this.global_utility.stopLoading();
          if (res && res.id > 0) {
            //this.employment = this.employmentDetailsModel;
            //this.hasEmployment = true;
            //this.mapEmploymentToModel(this._partInfo);

            res.quoteId = this.employmentDetailsModel.quoteId;
            this.employmentDetailsModel.workAdressLine1 = res.workAdressLine1;
            this.employmentDetailsModel.workAdressLine2 = res.workAdressLine2;
            this.employmentDetailsModel.workAdressSuburb = res.workAdressSuburb;
            this.employmentDetailsModel.workAdressTown = res.workAdressTown;
            this.employmentDetailsModel.workAdressAreaCode = res.workAdressAreaCode;
          }

          if (!res) {
            this.global_utility.info('Employer information NOT saved!! Was employment confirmed?', 'Action required');
          }
          this.modalRef?.close(res);
        }
        //alert('Employer information NOT saved!! Was employmnent confirmed?');
      });

      setTimeout(() => {
        if (savingemployment) {
          this.global_utility.info('Saving employer information is taking longer than expected, please wait.', 'Awaiting Response');
          this.global_utility.stopLoading();
        }
      }, 25000);
    }, 200);
  }


  validateEmployeeDetails() {
    this.startDate.setDate(1);
    this.endDate.setMonth(this.endDate.getMonth() + 1);
    this.endDate.setDate(1);
    this.endDate.setDate(this.endDate.getDate() - 1);

    this.employStartDate.setFullYear(this.employStartDate.getFullYear() - 5);
    this.employEndDate.setMonth(this.employEndDate.getMonth() - 3);

    // if (!this.employment || (this.employment.employerName?.toUpperCase() != 'SASSA' && !this.employment.employeeNumber)) {
    //   this.foundEmployer = true
    this.global_utility.stopLoading();


    //If SASSA employer
    if (this.quoteEmployName === 'SASSA') {
      this.employmentDetailsModel.employerName = this.quoteEmployName;
      this.employmentDetailsForm.controls['employeeNumber'].disable();
      this.employmentDetailsForm.controls['department'].disable();
      this.employmentDetailsForm.controls['placement'].disable();
      this.employmentDetailsForm.controls['occupation'].disable();
      this.employmentDetailsForm.controls['employeeNumber'].clearValidators();
      this.employmentDetailsForm.controls['department'].clearValidators();
      this.employmentDetailsForm.controls['placement'].clearValidators();
      this.employmentDetailsForm.controls['occupation'].clearValidators();
    }

    if (this.quoteEmployName != 'SASSA' && this.employment.employeeNumber) {
      this.mustConfirm = false;
      this.confirmEmployment = true;

      if (!this.employment.confirmedBy) {
        this.confirmEmployment = false;
      }
    }
    this.hasWorkaddress = false;
    this.employmentDetailsModel.workAdressLine1 = this.employment.physicalAddress1;
    this.employmentDetailsModel.workAdressLine2 = this.employment.physicalAddress2;
    this.employmentDetailsModel.workAdressSuburb = this.employment.physicalSuburb;
    this.employmentDetailsModel.workAdressTown = this.employment.physicalTown;
    this.employmentDetailsModel.workAdressAreaCode = this.employment.physicalCode;

    for (const address of this.addresses) {
      if (address.addressType == 'WORK') {
        this.hasWorkaddress = true;
        this.employmentDetailsModel.workAdressLine1 = address.line1;
        this.employmentDetailsModel.workAdressLine2 = address.line2;
        this.employmentDetailsModel.workAdressSuburb = address.suburb;
        this.employmentDetailsModel.workAdressAreaCode = address.code;
        this.employmentDetailsModel.workAdressTown = address.town;
      }
    }

    // Else tis will be incorrect on employer fetch
    this.employmentDetailsModel.id = this.employment.id;
    this.employmentDetailsModel.quoteId = this.employment.quoteId;
    this.employmentDetailsModel.serial = this.employment.branchSerial;
    this.employmentDetailsModel.employerTNR = this.employment.employerTNR;
    this.employmentDetailsModel.employmentType = this.employment.employmentType;
    this.employmentDetailsModel.address = this.employment.address;
    this.employmentDetailsModel.employmentStartDate = this.employment.employmentStartDate;
    this.employmentDetailsModel.contractStartDate = this.employment.contractStartDate;
    this.employmentDetailsModel.contractEndDate = this.employment.contractEndDate;
    this.employmentDetailsModel.wageCalendar = this.employment.wageCalendar;
    this.employmentDetailsModel.shiftingRule = this.employment.shiftingRule;

    this.employmentDetailsModel.occupation = this.employment.occupation;
    this.employmentDetailsModel.employerName = this.employment.name?.toUpperCase();
    this.employmentDetailsModel.payDate = this.employment.payDate;
    this.employmentDetailsModel.paymentMethod = this.employment.paymentMethod;

    if (!this.isReadOnly) {
      if (this.employment.employerTNR && !this.employment.employeeNumber) {
        this._partInfo = true;
        this.onEmploymentSelect({ id: this.employment.id, tnumber: this.employment.employerTNR });
      } else {
        this.hasEmployment = this.employment.employeeNumber && this.employment.employerTNR;
        this.mapEmploymentToModel();
      }
    }
  }

  editEmploymentTNR() {
    if (this.employment) {
      this.hasEmployment = false;
      this.mustConfirm = false;

      // this.employmentDetailsModel.occupation = this.employment.occupation;
      // // this.submitted = false;
      // this.employmentDetailsModel.employerName = this.employment.name?.toUpperCase();
      // this.employmentDetailsModel.payDate = this.employment.payDate;
      // this.employmentDetailsModel.paymentMethod = this.employment.paymentMethod;

      // this.employmentDetailsModel.parentCompany = this.employment.parentCompany || '';
      //this.employmentDetailsModel.placement = this.employment.placement || '';

      // if ((this.employment.employerTNR || this.employment.tnumber) && this.employmentDetailsModel.employerName) {
      //   //employer has previously been selected
      //   this.mapEmploymentToModel();
      // }
      // else {
      //   this.onEmploymentSelect({ id: this.employment.id, employerName: this.employmentDetailsModel.employerName });
      // }
    }
  }

  cancelEdit() {
    this.validateEmployeeDetails();
    this.hasEmployment = true;
  }

  mapEmploymentToModel(onlypart = false) {
    //This will always be from employment and should be populated already (new or not)
    this.employmentDetailsModel.employerName = this.employment.name;
    //this.employerNametxt = this.employmentDetailsModel.employerName;
    this.employmentDetailsModel.parentCompany = this.employment.parentCompany;
    this.employmentDetailsModel.department = this.employment.department;
    this.employmentDetailsModel.occupation = this.employment.occupation;
    this.employmentDetailsModel.placement = this.employment.placement;


    if (onlypart) {
      this.employmentDetailsModel.wageCalendar ||= this.employment.luEmpPayFrequency;
      this.employmentDetailsModel.paymentMethod = this.employment.luSalaryMethodDesc;
      this.employmentDetailsModel.shiftingRule = this.employment.luPaydateShiftDesc;
      this.employmentDetailsModel.payDate = this.employment.payDate;
      this.employmentDetailsModel.employerTNR = this.employment.tnumber;

      if (!this.hasWorkaddress) {
        this.hasWorkaddress = true;
        this.employmentDetailsModel.workAdressLine1 = this.employment.physicalAddress1;
        this.employmentDetailsModel.workAdressLine2 = this.employment.physicalAddress2;
        this.employmentDetailsModel.workAdressSuburb = this.employment.physicalSuburb;
        this.employmentDetailsModel.workAdressTown = this.employment.physicalTown;
        this.employmentDetailsModel.workAdressAreaCode = this.employment.physicalCode;
      }
      return;
    }

    //_partInfo = true If employment details not yet captured or first time capture
    //We only want to populate if we have full details
    if (!this._partInfo) {
      this.employmentDetailsModel.employerTNR = this.employment.employerTNR;
      this.employmentDetailsModel.lineManagerFirstName ||= this.employmentDetailsModel.employerName == 'SASSA' ? 'sassa' : this.employment.lineManagerFirstName;
      this.employmentDetailsModel.lineManagerLastName ||= this.employmentDetailsModel.employerName == 'SASSA' ? 'sassa' : this.employment.lineManagerLastName;
      this.employmentDetailsModel.lineManagerEmail = this.employment.lineManagerEmail;
      this.employmentDetailsModel.lineManagerContactNumber = this.employment.lineManagerContactNumber;

      this.employmentDetailsModel.employeeNumber ||= this.employmentDetailsModel.employerName == 'SASSA' ? 'sassa' : this.employment.employeeNumber;
      this.employmentDetailsModel.paymentMethod = this.employment.paymentMethod;
      this.employmentDetailsModel.shiftingRule = this.employment.shiftingRule;
      this.employmentDetailsModel.payDate = this.employment.payDate;

      if (!this.hasWorkaddress && this.employment.address) {
        this.employmentDetailsModel.workAdressLine1 = this.employment.address.line1;
        this.employmentDetailsModel.workAdressLine2 = this.employment.address.line2;
        this.employmentDetailsModel.workAdressSuburb = this.employment.address.suburb;
        this.employmentDetailsModel.workAdressTown = this.employment.address.town;
        this.employmentDetailsModel.workAdressAreaCode = this.employment.address.code;
      }
      else {
        if (!this.hasWorkaddress) {
          this.hasWorkaddress = true;
          this.employmentDetailsModel.workAdressLine1 = this.employment.workAdressLine1;
          this.employmentDetailsModel.workAdressLine2 = this.employment.workAdressLine2;
          this.employmentDetailsModel.workAdressSuburb = this.employment.workAdressSuburb;
          this.employmentDetailsModel.workAdressTown = this.employment.workAdressTown;
          this.employmentDetailsModel.workAdressAreaCode = this.employment.workAdressAreaCode;
        }
      }
      this.employmentDetailsModel.confirmed ||= this.employment.confirmedBy != '';
      this.employmentDetailsModel.confirmedByUser ||= this.employment.confirmedBy;
    }
  }

  mapEmployerToFormForce(employer: any) {
    if (employer) {
      this.employmentDetailsModel.serial = employer.branchSerial || employer.serial;
      // this.employerNametxt = employer.name;
      this.employmentDetailsModel.employerName = employer.name;
      this.employmentDetailsModel.parentCompany = employer.parentCompany || this.employment.parentCompany;

      this.employmentDetailsModel.payDate = employer.payDate || this.employment.payDate;
      this.employmentDetailsModel.shiftingRule = employer.luPaydateShiftDesc;
      this.employmentDetailsModel.branch = employer.branch || employer.employerBranch;
      this.employmentDetailsModel.branchName = employer.branchName;
      this.employmentDetailsModel.company = employer.company;
      this.employmentDetailsModel.wageCalendar = employer.luEmpPayFrequency;
      this.employmentDetailsModel.paymentMethod = employer.luSalaryMethodDesc;
      this.employmentDetailsModel.workAdressLine1 = employer.physicalAddress1;
      this.employmentDetailsModel.workAdressLine2 = employer.physicalAddress2;
      this.employmentDetailsModel.workAdressSuburb = employer.physicalSuburb;
      this.employmentDetailsModel.workAdressTown = employer.physicalTown;
      this.employmentDetailsModel.workAdressAreaCode = employer.physicalCode;

      this.employmentDetailsModel.employerTNR = employer.tnumber ?? employer.employerTNR;
      this.employmentDetailsModel.lineManagerEmail = employer.email;
      this.employmentDetailsModel.lineManagerContactNumber = employer.tel1;
      this.employmentDetailsModel.occupation = employer.occupation;
    }
  }

  // onEmploymentStartDateSelect(event: any) {
  //   const seletedDate = new Date(formatDate(event.target.value, 'yyyy-MM-dd', 'en'));
  //   console.log(seletedDate, "... selected Date");
  //   console.log(this.employEndDate, " this.employEndDate");

  //   if (this.employEndDate < seletedDate) {
  //     this.isValidDate = false;
  //     //alert('Client must be employed for longer that 3 months');
  //     this.toastrService.warning('Client must be employed for at least 3 months', 'Employment', { positionClass: 'toast-top-center' });
  //     return;
  //   } else {
  //     this.isValidDate = true;
  //   }
  // }

  goforNew() {
    const newemployer = new EmployerDetailsModel();
    this.branchEmployerService.preppedforCreate = true;
    this.modalservice.open(UpdateEmployerComponent, {
      backdrop: true, ignoreBackdropClick: true, keyboard: true, nonInvasive: false, animation: true,
      modalClass: 'modal-xl modal-dialog d-flex d-flex-lg',
      containerClass: 'modal fade show',
      data: {
        employerDetailsModel: newemployer
      }
    }).onClose.subscribe((employer) => {
      if (employer) {
        //this.doEmployerConfirm(employer);

        //this.isEmploymentComplete = true;

        //this.quoteStoreService.quoteWorkbook.employment = {...currentEmployment, ...employer};
        //this.mapWorkAddress(employer);
        //this.checkEmploymentComplete();

        //this.openEmploymentDialog();

        this.employmentDetailsModel.wageCalendar = this.employmentDetailsModel.wageCalendar = this.employmentDetailsModel.payFrequency || '';
        this.employmentDetailsModel.employerName = this.employmentDetailsModel.employerName || this.branchEmployerService.employerDetailsModel.employerName;
        // this.employerNametxt = this.employmentDetailsModel.employerName;
        return true;
      }

      this.global_utility.warning('Employment details not saved', 'Incomplete employment');
      return false;
    });
  }

  ReloadEmployer() {
    // this.RefreshEmployer = true;
  }

  onEmploymentSelect(event: any) {
    let employerId = event.target?.value || 0;

    if (event && event.tnumber) {
      //const tnumber = event.tnumber;

      console.log("onEmploymentSelect");
      // const employerFind = this.findEmployerByTNR(tnumber);
      // console.log(employerFind, "employerFind");
      // this.dialog.open(ConfirmDialogComponent, {
      //   width: '650px',
      //   height: 'auto',
      //   autoFocus: true,
      //   data: {
      //     title: 'Retrieve employer details',
      //     message: 'Do you want to fetch employer details and update this form?',
      //     showClose: false,
      //     isErrorOccured: false,
      //   },
      // }).afterClosed().subscribe({
      //   next: doFetch => {
      //     if (doFetch) {
      //       const employerFind = this.findEmployerByTNR(tnumber);
      //       employerFind.then((found: boolean) => {
      //         if (found) {
      //         //  this.employment = this.lstEmployers;
      //           this._partInfo = true;
      //           this.mapEmploymentToModel(this._partInfo);
      //         } else {
      //           //this.hasEmployment = false;
      //           this.global_utility.info('Employer not found. Please create this employer', 'Employer not found');
      //           //Info from application
      //           // let newemployer = new EmployerDetailsModel();

      //           // newemployer.employerName = this.employment.name;
      //           // newemployer.payDate = this.employment.payDate;
      //           // newemployer.luEmpPayFrequency = this.employment.wageCalendar;

      //           // this.branchEmployerService.preppedforCreate = true;
      //           // this.dialogRef?.close({ donew: true, employer: newemployer });
      //         }
      //       });
      //       this.refreshEmployment = false;
      //     }
      //     else {
      //       // Should edit be cancelled?
      //     }

      //   }
      // });
      return;
    }

    // Below is possible depricated with above return
    if (!isNaN(event.id) && event.employerName) {
      employerId = event.id;
      //this.employerNametxt = event.employerName;

      // if (event.employerTNR) {
      //   this.mapEmployerToFormForce(this.lstEmployers);
      //   return;
      // }

      // cant check id DONT DO -> oemploy.id === employerId <- one is employment id and other is employer id
      // let ell = this.lstEmployers.filter((oemploy) => oemploy.name === event.employerName);
      // if (!ell || ell.length == 0) {
      //   this.doEmployerConfirm(event.employerName);
      // }

      // if (ell && ell.length > 0) {
      //   this.mapEmployerToFormForce(ell[0]);
      //   return;
      // }

      return;
    }

    if (Array.isArray(this.lstEmployers)) {
      for (const employ of this.lstEmployers) {
        if (employerId == employ.id) {
          console.log(employ, "..employ");

          this.mapEmployerToFormForce(employ);
          break;
        }
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  isContactNumberLength() {}

  covertDate(date: any) {
    if (date != null) {
      let str = date;
      str = str.substring(0, 10);
      date = str;
      return date; // this.myDatepipe.transform(date, 'dd/MM/yyyy');
    } else {
      return date;
    }
  }

  // doEmployerConfirm(employerName: string) {
  //   let employersSearch = { employerName: employerName, branchSerial: this.userService.userBranchSerial };

  //   const promise = new Promise<boolean>((resolve, reject) => {
  //     this.branchEmployerService.findEmployersByName(employersSearch).subscribe((employerList: any) => {
  //       //hasEmployment will enable the dropdown on Edit
  //       if (employerList && employerList.length > 0) {
  //         resolve(true);
  //       }
  //       else {
  //         resolve(false);
  //       }
  //     });
  //   });

  //   promise.then((found: boolean) => {
  //     if (found) {
  //       setTimeout(() => {
  //         this.mapEmployerToFormForce(this.branchEmployerService.employerDetailsModel);
  //       }, 400);
  //     }
  //   });
  // }

  confirmEmploymentDetails() {
    this.employmentDetailsModel.isConfirmedEmploymentDetails = true;
    this.modalservice.open(EmploymentDialogComponent,
      {
        modalClass: 'modal-md modal-dialog',
      }).onClose.subscribe(result => {
      this.confirmEmployment = result && result.empConfirmed;

      this.doSaveEmploymentDetails();
    });

    //this.mapEmploymentToModel();

    this.modalRef.close(this.employmentDetailsModel);
  }
}


