import { Component } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  MatSlideToggleModule,
  _MatSlideToggleRequiredValidatorModule
} from '@angular/material/slide-toggle';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { AppUtilityService } from '../app-utility.service';
import { ClientSearchModel } from '../core/models/client-search.model';

@Component({
  selector: 'app-employment-dialog',
  templateUrl: './employment-dialog.component.html',
  styleUrls: ['./employment-dialog.component.css'],
  standalone: true,
  imports: [ MatSlideToggleModule, FormsModule, ReactiveFormsModule ],

})
export class EmploymentDialogComponent {
  employDialogForm: FormGroup;
  clientSearchModel: ClientSearchModel = new ClientSearchModel();
  userName: any = localStorage.getItem('username');

  isChecked = false;
  confirmedForm = this.fb.group({
    empConfirmed: [false, Validators.requiredTrue],
  });

  constructor(
    //    public quoteStoreService: QuoteStoreService
    private fb: FormBuilder,
    public global_utility: AppUtilityService,
    public modalRef: MdbModalRef<EmploymentDialogComponent>) { }

  close() {
    this.modalRef.close();
  }

  save() {
    if (!this.confirmedForm.value || !this.confirmedForm.value.empConfirmed) {
      this.global_utility.info('Are you sure you want to continue without confirming employment?', 'Employment');
    }

    return this.modalRef.close(this.confirmedForm.value);
    //alert("Please confirm that the Employer was contacted.")
  }
}
