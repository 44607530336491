import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, of, throwError, TimeoutError, catchError } from 'rxjs';
import { ApiloaderService } from 'src/app/shared/loaders/apiloader/apiloader.service';
import { LoadingSpinService } from 'src/app/shared/loaders/spinner/loadspinner.service';
import { environment } from 'src/environments/environment';
import { ErrorhanderService } from '../services/error-handler.service';

// export function ErrorInterceptorFn(req: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
//   const loaderService = inject(LoadingSpinService);
//   const this.errorService = inject(ErrorhanderService);
//   const this.loaderService = inject(this.loaderService);

//   const reqWithHeader = req.clone();

//   return next(reqWithHeader)
// }

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private apiloader: ApiloaderService,
    private loaderService: LoadingSpinService,
    private errorService: ErrorhanderService,
    private toastrService: ToastrService,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let handled = false;

    return next.handle(req).pipe(
      catchError((returnedError) => {
        if(returnedError instanceof Error || (req.url?.endsWith('doLoanCreate') && returnedError instanceof HttpErrorResponse)){
          let errorMessage = '';
          // NUPAY Error response

          if((returnedError as HttpErrorResponse).status === 504 && (returnedError as HttpErrorResponse).statusText == 'Gateway Timeout'){
            this.errorService.handleOffline((returnedError as HttpErrorResponse).statusText, 'API/Server OFFLINE');
            this.loaderService.startLoading();
            const newerror = new Error('NO Response!! OFFLINE');
            return throwError(()=> newerror);
          }
          errorMessage = (returnedError as HttpErrorResponse).error.message || returnedError.message;
          console.error('NupayResult ERROR>> ', errorMessage);
          // return throwError(() => {
          const newerror = new Error(errorMessage);
          //});
          return throwError(()=> newerror);
        }

        if(returnedError instanceof HttpErrorResponse) {
          if(isDevMode()){
            console.warn('Error catch Handled >> ', returnedError);
          }

          if(returnedError.status === 504)
          {
            this.loaderService.startLoading();

            if(environment.showtrace ) {
              console.error('API/Server ERROR >> ', returnedError.statusText);
              this.errorService.handleOffline(returnedError.message, 'API/Server OFFLINE');
              // this.toastrService.warning(returnedError.statusText,'API/Server OFFLINE',{ positionClass:'toast-top-right', disableTimeOut: false,timeOut: 3000, easeTime:500});
            } else {
              console.error('API/Server ERROR: ', returnedError.statusText);
              this.errorService.handleOffline(returnedError.statusText, 'API/Server OFFLINE');
              //this.loaderService.error(`${returnedError.statusText}`,'API/Server OFFLINE',{disableTimeOut:true, positionClass:'toast-top-right', tapToDismiss: true});
            }
            return throwError(()=> returnedError);
          }

          let errorMessage = '';
          if (handled) {
            //this.errorService.handleOffline(returnedError.message, 'Server OFFLINE');
            return of(returnedError);
          }

          handled = false;
          // **  ERROR EVENT ** //
          if (returnedError.error instanceof ErrorEvent) {
            errorMessage = `Error: ${returnedError.error!.message || returnedError.message}`;
            console.error(errorMessage);
            const newerror = new Error(errorMessage);
            this.errorService.handle(newerror, `ERROR Event.`, true);

            return throwError(() => newerror );
          }

          // **  TIMEOUT ERROR ** //
          else if (!handled && (returnedError instanceof TimeoutError)) {
            //this.loaderService.warning('Request timeout occured', `Request timed-out`, { positionClass: 'toast-top-center', disableTimeOut: true, closeButton: true });
            // this.errorService.handleOffline(returnedError.message, 'Server not responding');
            this.loaderService.stopLoading();
            return throwError(() => returnedError );
          }
          // **  HTTP RESPONSE ERROR ** //
          else if (!handled && returnedError instanceof HttpErrorResponse) {
            // **  PROGRESS ERROR ** //
            if (!handled && returnedError.status == 0 && returnedError.error instanceof ProgressEvent) {
              if (!returnedError.url?.endsWith('doLoanCreate')) {
                //handled = handleProgressEvent(returnedError);
                const message1 = returnedError.url ?? '';
                const hdrString1 = `Network error`;
                const showdateStr1 = new Date().toLocaleString();
                this.toastrService.error(`${showdateStr1} : ${message1}`, hdrString1, { positionClass: 'toast-top-center', disableTimeOut: true, closeButton: true, progressAnimation: 'increasing' });
                this.loaderService.stopLoading();
                handled = true;
              }
            }

            switch (returnedError.status) {
            case 302: // Found REsponse
              return throwError(() => {
                const error: any = new Error('Duplicate Record Found!');
                this.errorService.handle(error, `LMS Server Duplicate not allowed!!`, true);
                return returnedError;
              });
            case 417:
              if (!returnedError.url?.endsWith('doLoanCreate')) {
                // NUPAY Error response
                console.error('LMS SERVER ::', returnedError.error.message);
                const dispMsg = returnedError.error.message || returnedError.message;

                const newerror = new Error(dispMsg);
                this.errorService.handle(newerror, `LMS Server [response]`, true);

                return throwError(() => {
                  const error: any = new Error(dispMsg);
                  return error;
                });
              }
              break;
            case 501:
              this.errorService.handleOffline(returnedError.error.message, 'Server OFFLINE');
              handled = true;
              break;
            case 400: // Bad Request
              this.toastrService.error(`${new Date().toLocaleString()} Server response:<br><br>${returnedError.error.message ?? ''}`, 'Bad Request error', { enableHtml:true, positionClass: 'toast-top-center', timeOut: 18000, newestOnTop: true, disableTimeOut: true, tapToDismiss: true });
              this.loaderService.stopLoading();
              handled = true;

              //handled = handleBadRequest(returnedError);
              break;
            case 504: // Bad Gateway
              // const message1 = returnedError.error.message ?? '';
              // const hdrString1 = 'Bad Request error';
              // const showdateStr1 = `${new Date().toLocaleString()}`;
              this.toastrService.error(`${new Date().toLocaleString()} Server response:<br><br>${returnedError.error.message ?? ''}`, 'Bad Request error', { enableHtml:true, positionClass: 'toast-top-center', timeOut: 18000, newestOnTop: true, disableTimeOut: true, tapToDismiss: true });
              this.loaderService.stopLoading();
              handled = true;
              ///handled = handleBadGateway(returnedError);
              break;
            default:
              if ((returnedError.error && returnedError.error == 'Unauthorized') && returnedError.status == 401) {
                this.errorService.handleString("Unauthorized recieved. Require login");
                handled = true;
              } else if ((returnedError.error && returnedError.error.type == 'error') && returnedError.statusText === 'Unknown Error') {
                //Offline
                this.errorService.handleOffline(returnedError.message || returnedError.error.message, 'Server OFFLINE');
                handled = true;
              } else {
                if (returnedError.error && !returnedError.error!.result) {
                  handled = this.errorService.handleServerSideError(returnedError);
                  break;
                }

                if ((returnedError.error?.trace || returnedError.error?.stackTrace) && (!environment?.production && !!environment?.showtrace)) {
                  this.errorService.handleStack(returnedError.error?.trace || returnedError.error?.stackTrace);
                }
              }
              if ((returnedError.error?.trace || returnedError.error?.stackTrace) && (!environment?.production && !!environment?.showtrace)) {
                this.errorService.handleStack(returnedError.error?.trace || returnedError.error?.stackTrace);
              }
              break;
            }

            if (!handled && returnedError.error && returnedError.error.name == 'TimeoutError') {
              this.toastrService.warning('Timeout has occured', `Timeout has occured, refresh?`, { positionClass: 'toast-top-center', disableTimeOut: true, closeButton: true, progressAnimation: 'increasing' });
              this.loaderService.stopLoading();
              handled = true;
            }

          }
          return throwError(()=> {
            errorMessage = `Unhandled error ${returnedError?.status}: ${returnedError?.error?.message || returnedError?.message}`;
            const error: any = new Error(errorMessage);
            this.errorService.handle(error);
            return error;
          });


          // //errorMessage = `${returnedError.error!.path}[${returnedError.status}]: ${returnedError.error!.message || returnedError.message || returnedError.error?.error!.message}`;
          // const errorMessage = `Unhandled error ${returnedError?.status}: ${returnedError?.error?.message || returnedError?.message}`;
          // console.error(errorMessage);

          // return throwError(()=> {
          //   const error: any = new Error(errorMessage);
          //   this.errorService.handle(error);
          //   return error;
          // });
        } else {
          return of(returnedError);
        }
      }))
  }

  //   private handleProgressEvent = (error: HttpErrorResponse): boolean {
  //     const message1 = error.url ?? '';
  //     const hdrString1 = `Network error`;
  //     const showdateStr1 = `${new Date().toLocaleString()}`;
  //     this.loaderService.error(`${showdateStr1} : ${message1}`, `${hdrString1}`, { positionClass: 'toast-top-center', disableTimeOut: true, closeButton: true, progressAnimation: 'increasing' });
  //     loaderService.stopLoading();
  //     apiloader.isAPIOffline.next(true);
  //     return true;
  //   }

  //   private handleBadRequest = (error: HttpErrorResponse): boolean {
  //     const message1 = error.error.message ?? '';
  //     const hdrString1 = 'Bad Request error';
  //     const showdateStr1 = `${new Date().toLocaleString()}`;
  //     this.loaderService.error(`${showdateStr1} Server response:<br><br>${message1}`, `${hdrString1}`, { enableHtml:true, positionClass: 'toast-top-center', timeOut: 18000, newestOnTop: true, disableTimeOut: true, tapToDismiss: true });
  //     loaderService.stopLoading();
  //     return true;
  //   }

//   private handleBadGateway(error: HttpErrorResponse): boolean {
//     const message1 = error.url ?? '';
//     const hdrString1 = `Bad Gateway error : ` + error.statusText;
//     const showdateStr1 = `${new Date().toLocaleString()}`;
//     this.loaderService.error(`${showdateStr1} : ${message1}`, `${hdrString1}`, { positionClass: 'toast-top-center', timeOut: 18000, newestOnTop: true, disableTimeOut: true, tapToDismiss: true });
//     loaderService.stopLoading();
//     apiloader.isAPIOffline.next(true);
//     return true;
//   }
}
