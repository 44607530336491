import {
  EventEmitter,
  Injectable,
  Output,
  OnDestroy,
  inject,
} from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import {
  KeycloakService,
  KeycloakEventType,
  type KeycloakEvent,
} from 'keycloak-angular';
import Keycloak from 'keycloak-js';
import { type KeycloakProfile } from 'keycloak-js';
import {
  BehaviorSubject,
  catchError,
  map,
  Observable,
  of,
  Subject,
  Subscription,
  tap,
} from 'rxjs';
import { environment } from 'src/environments/environment';
//import { AuthenticationService } from './authentication.service';
import { AppUtilityService } from 'src/app/app-utility.service';

@Injectable({providedIn:'root'})
export class UserService implements OnDestroy {
  @Output() onLogginChanged = new EventEmitter<boolean>();
  @Output() KCEventChanged = new EventEmitter<KeycloakEvent>();
  public LMSUserEvent$ = new Subject<KeycloakEvent>(); //</KeycloakEvent>;//({type: KeycloakEventType.OnReady});
  private _lmsProfile = new BehaviorSubject<any>({});
  private _isUserLoggedin = new BehaviorSubject<boolean>(false);
  public ValidUserLoaded$ = this._isUserLoggedin;

  private _menusControl = new BehaviorSubject<any[]>([]);
  private _menuOriginal = new BehaviorSubject<any[]>([]);
  private fingerAuthentication = new BehaviorSubject<boolean>(false);

  //currentUser: User | null = this.auth.currentUser;
  private userSubscription: Subscription[] = [];

  private userBranch_apiURL = `${environment.apiUrl}/loan/api/user-branch`;
  private menu_apiURL = `${environment.apiUrl}/loan/menu`;
  accessToken: any;
  userDetails = new BehaviorSubject<any>({});
  private _currentKCProfile!: KeycloakProfile;
  private _currentKC: KeycloakService;
  private _fingerAuthenticated: boolean;

  private MenuOriginal$ = this._menuOriginal.asObservable();
  public LMSProfile$ = this._lmsProfile.asObservable();
  public MenusControl$ = this._menusControl;
  public lmsKeycloak: Keycloak;
  HttpParamsOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(
    private http: HttpClient,
    //private _currentKC: KeycloakService,
    //private authService: AuthenticationService,
    private global_utility: AppUtilityService,
  ) {
    //this.lmsKeycloak = this._currentKC!.getKeycloakInstance();
    this._currentKC = inject(KeycloakService);

    if (this._currentKC && this._currentKC.keycloakEvents$) {
      this.lmsKeycloak = this._currentKC!.getKeycloakInstance();
      this._currentKC.keycloakEvents$.subscribe({
        next: (kcEvent: KeycloakEvent) => {
          if (kcEvent.type == KeycloakEventType.OnAuthSuccess) {
            this._currentKCProfile =
              (kcEvent.args as KeycloakProfile) ||
              this._currentKC.getKeycloakInstance().profile;
            this.getLMSUserProfile(
              this._currentKCProfile?.id ||
                this._currentKC.getKeycloakInstance().subject,
            );
          }
          if (kcEvent.type == KeycloakEventType.OnReady) {
            if (!kcEvent.args) {
              this.getLMSUserProfile(
                this._currentKC!.getKeycloakInstance().profile?.id,
              );
              //this._currentKC.login();
            }
            // this.usertoken = `Token exp(5): ${localStorage.getItem('expires_at')||''}`;
            // this.usertoken += ` JWT exp(5): ${localStorage.getItem('jwtTkn')}`;
            //this.getLMSUserProfile(evt.args as KeycloakProfile);
          }
          if (kcEvent.type == KeycloakEventType.OnAuthRefreshSuccess) {
            //this.authService.login();
          }
          if (kcEvent.type == KeycloakEventType.OnTokenExpired) {
            //this.global_utility.warning('User session expired. Token to be refreshed, please wait.', 'User token expired');
            //this.getLMSUserProfile(evt.args as KeycloakProfile);
          }
          if (kcEvent.type == KeycloakEventType.OnActionUpdate) {
            //this.global_utility.warning('User action Update.', 'User action update');
            //this.getLMSUserProfile(evt.args as KeycloakProfile);
          }
          if (kcEvent.type == KeycloakEventType.OnAuthRefreshError) {
            console.log(
              'Auth refresh Error: ',
              this._currentKC.getKeycloakInstance().refreshToken,
            );
          }

          //this.authService.login(this.keycloak.getKeycloakInstance().subject!);
          this.LMSUserEvent$.next(kcEvent);
        },
      });

      const keycloackEvent = this.LMSUserEvent$.subscribe((kcEvent) => {
        this.KCEventChanged.emit(kcEvent);
      });
      this.userSubscription.push(keycloackEvent);
    }
    //this._currentKC = inject(KeycloakService);
    //const uuid = profile.id || this.keycloak.getKeycloakInstance().subject!;
    //this.setLMSKeycloack(this.kc);

    // this.isUserLoggedin$.subscribe((validuser)=>{
    //   if(validuser) {
    //     this.keycloak.loadUserProfile().then(async (profile: KeycloakProfile) => {
    //     this.getLMSUserProfile(profile);
    //     });
    //     return;
    //   }

    //   if(validuser!!){
    //     this.keycloak.login().then(() => {
    //       redirectUri: window.location.origin;
    //     });
    //     return;
    //   }
    // })
  }

  get isKCAuthenticated() {
    return (
      this._currentKC.getKeycloakInstance().authenticated &&
      !this._currentKC.getKeycloakInstance().loginRequired
    );
  }

  public setLMSKeycloack() {
    // if(kc!.getKeycloakInstance().authenticated && !this._isUserLoggedin.getValue()) {
    this.getLMSUserProfile(this._currentKC.getKeycloakInstance().profile?.id);
    // }
  }

  private useLMSProfile = (
    lmsprofile: any,
    withValue: boolean,
    emitEvent?: boolean,
  ) => {
    console.group('Using LMSProfile');
    console.log(' >> ', lmsprofile);

    if (lmsprofile) {
      this._lmsProfile.next(lmsprofile);
    }
    console.log('with Value >>', withValue);
    this._isUserLoggedin.next(withValue);

    console.log('Emit Event >>', emitEvent);
    if (this.onLogginChanged && emitEvent) {
      this.onLogginChanged.emit(withValue);
    }
    console.groupEnd();
  };

  private useUserMenus = (menus: any[]) => {
    this._menusControl.next(menus);
  };

  private getLMSUserProfile(profileUUID: string | undefined) {
    if (!profileUUID) {
      this.useLMSProfile(null, false);
      this.setFingerValid(false);
      return;
    }

    if (this._isUserLoggedin.getValue()) {
      this.useLMSProfile(null, true, true);
      return;
    }

    this.getLoggedInUserDetails(profileUUID).subscribe({
      next: (lmsUser: any) => {
        // map((user: any) => {})
        if (lmsUser) {
          this.global_utility.setGlobalData('userDisplay', lmsUser.nameSurname);
          this.global_utility.setGlobalData(
            'user_LoginName',
            lmsUser.loginName,
          );
          this.global_utility.setGlobalData(
            'branch_serial',
            lmsUser.delfinBranchSerial,
          );
          this.global_utility.setGlobalData('branch_Name', lmsUser.branchName);
          this.global_utility.setGlobalData(
            'user_Landing',
            lmsUser.jobTitle.component,
          );

          console.log('GetLogged in User details >> ', lmsUser);
        }

        this.getUserMenu(profileUUID, lmsUser);

        if (lmsUser && !this._isUserLoggedin.getValue()) {
          this.setFingerValid(false);

          //this.storageService.saveUser(lmsUser);
          let component = lmsUser!.jobTitle?.component;
          this.global_utility.setGlobalData('user_Landing', component);
          if (
            !component ||
            component === 'branchconsultant' ||
            lmsUser.jobTitle!.jobTitle === 'System Owner'
          ) {
            component = '/agent';
            this.global_utility.setGlobalData('user_Landing', '/agent');

            lmsUser.jobTitle.component = '/agent';
            //component = '/fingerprintSignin';
          }
          this.global_utility.navigateToURL([component]);
          this.useLMSProfile(lmsUser, true, true);
        }
      },
      // error: (error) => {
      //   if (error instanceof HttpErrorResponse) {
      //     console.error('LMS User ERROR >> ',error);
      //     this.global_utility!.error('LMSUser: '+error, 'User Verification Error');

      //     if ((error.error && error.error.type == 'error') && error.statusText === 'Unknown Error') {
      //       //reject('SERVER OFFLINE');

      //     }
      //   }
      // }
    });
  }

  private getLoggedInUserDetails(uuid: string) {
    return this.http.get<any>(`${this.userBranch_apiURL}/uuid/${uuid}`);
  }

  private async getUserMenu(uuid: string, usrDetails: any) {
    if (!this.userDetails) {
      return;
    }
    // All Keycloak ROLES
    const filteredRoles = this._currentKC.getUserRoles(true);

    //Filtered Keycloak roles
    const rolefilter = filteredRoles.filter(
      (role) =>
        role == 'ROLE_ADMIN' ||
        role == 'ROLE_SUPER_USER' ||
        role == 'ROLE_USER' ||
        role == 'ROLE_APPROVER',
    );
    //console.log(rolefilter);

    // BUILD MENU
    this.userSubscription.push(
      this.getUserMenus(
        rolefilter.join(','),
        uuid,
        usrDetails.jobTitle,
      ).subscribe({
        next: (menus1) => {
          if (!this._menuOriginal.getValue()) {
            this._menuOriginal.next(menus1);
          }

          if (menus1 && menus1.length > 0) {
            this.useUserMenus(menus1);
          }
        },
      }),
    );
  }

  setNoMenus(hideMenus: boolean) {
    if (hideMenus) {
      this._menusControl.next([]);
      return;
    }

    if (this._menuOriginal.getValue()) {
      //const previousMenus = this.menuHold.value;
      this.useUserMenus(this._menuOriginal.getValue());
    }
  }

  private getUserMenus(roles: any, uuid: any, jobTitle: any) {
    const userRoles = { roles, uuid, jobTitle };
    // const userNode = { uuid: uuid, roles: roles };
    // console.log('menu', 'menu');
    return this.http
      .post<any>(`${this.menu_apiURL}/getMenusByUuid`, userRoles)
      .pipe(
        catchError((err, caught) => {
          this.global_utility.error(err, 'User not LOaded');
          return caught;
        }),
      );
  }

  public CurrentLMSUserBranchValue = () => {
    return this._lmsProfile.getValue();
  };

  get isFingerAuth() {
    return this.fingerAuthentication.getValue() || this._fingerAuthenticated;
  }

  public CurrentUser_Menus = () => {
    return this._menusControl.getValue();
  };

  get CurrentUser() {
    const usr = this._lmsProfile.getValue();
    return usr;
  }

  public setFingerValid(validBoolean: boolean) {
    // this.toastrService.info('FingerAuth changing: fingerValid is ' + { validBoolean }, '', { positionClass: 'toast-top-center' });
    this._fingerAuthenticated = validBoolean;
    this.fingerAuthentication.next(validBoolean);
  }

  get userLoginName() {
    return `${this._lmsProfile.getValue().loginName || ''}`;
  }

  get userUUID() {
    return `${this._lmsProfile.getValue().keyclockUuid}`;
  }

  get userDisplayName() {
    return this._lmsProfile.getValue().nameSurname || '';
  }

  get userBranchName() {
    return `${this._lmsProfile.getValue().branchName}`;
  }
  get userBranchNumber() {
    return `${this._lmsProfile.getValue().branchNumber}`;
  }

  get userBranchSerial() {
    return `${this._lmsProfile.getValue().delfinBranchSerial}`;
  }

  get userJobTitle() {
    return this._lmsProfile.getValue().jobTitle;
  }

  logOutUser() {
    this.setFingerValid(false);
    this.setNoMenus(true);
    this._currentKC.logout();
  }

  // getPublicContent(): Observable<any> {
  //   return this.http.get(this.userBranch_apiURL + 'all', { responseType: 'text' }).pipe(
  //     catchError((err,caught) => {
  //       this.global_utility.error(err, 'User not LOaded');
  //       return caught;
  //     }
  //     ));
  // }

  getUserAreaBranches(area: string): Observable<any> {
    return this.http
      .get(`${this.userBranch_apiURL}/userbranchareas/${area}`)
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((err, obs) => {
          obs.pipe(
            tap((val) => {
              console.error('LMS User tap ERROR >> ', val);
            }),
          );
          console.error('LMS User ERROR >> ', err);
          this.global_utility!.error(
            'LMSUser: ' + err,
            'User Verification Error',
          );
          if (err instanceof HttpErrorResponse) {
            if (
              err.error &&
              err.error.type == 'error' &&
              err.statusText === 'Unknown Error'
            ) {
              //reject('SERVER OFFLINE');
            }
          }
          return of(err);
        }),
      );
  }
  // handleError(error: any) {

  //   return of(error);
  //     /reject('SERVER OFFLINE');

  //   let errorMessage = '';
  //   if (error.error instanceof ErrorEvent) {
  //     errorMessage = error.error.message;
  //   } else {
  //     errorMessage = `Server responded with status ${error.status} and the message: ${error.message}`;
  //   }

  //   return of(errorMessage);
  // }

  ngOnDestroy() {
    this.userSubscription.forEach((x) => {
      x.unsubscribe();
    });

    this._menusControl.unsubscribe();
    this._lmsProfile.unsubscribe();
  }
}
