import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormsModule, ReactiveFormsModule} from '@angular/forms';
import { ReportService } from 'src/app/core/rest-services/report.service';
import * as XLSX from 'xlsx';
import { NgxPaginationModule, PaginationInstance } from 'ngx-pagination';
import { MatFormFieldModule } from '@angular/material/form-field';
//import { CommonModule } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ZARcurrencyPipe } from '@lmsSharedPipes';
import { BranchLoansReportModel } from '@lmsModels';
import { LmsPaginateComponent } from '@lmsShared';
import { AppUtilityService } from 'src/app/app-utility.service';

@Component({
  selector: 'app-lexis-nexis-report',
  templateUrl: './lexis-nexis-report.component.html',
  styleUrls: ['./lexis-nexis-report.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatButtonModule,
    NgxPaginationModule,
    LmsPaginateComponent,
    FormsModule,
    ReactiveFormsModule,
    DecimalPipe, 
    DatePipe, 
    ZARcurrencyPipe
  ]
})
export class LexisNexisReportComponent implements OnInit {
  lexisNexisReportForm: FormGroup;
  startDate: string;
  endDate: string;
  idNumber: string;
  selectedBranch: any;
  branchLoansReportModel = new BranchLoansReportModel();
  lstLexisNexis: any[] = [];
  title = 'export-excel';
  fileName = '';

  @ViewChild('TABLE') table: ElementRef;
  dataSource = this.lstLexisNexis;

  //PAGINATOR CODE
   @Input() items: any[];
   @Output() changePage = new EventEmitter<any>(true);
   @Input() initialPage = 1;
   @Input() pageSize = 15;
   @Input() maxPages = 5;
  config: PaginationInstance = {
    id: 'pager',
    itemsPerPage: 10,
    currentPage: 1
  };
  docCount = 1;

  constructor(
    public reportService: ReportService,
    public global_utility: AppUtilityService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.lexisNexisReportForm = this.formBuilder.group({
      branch: [this.selectedBranch],
      startDate: new Date(this.branchLoansReportModel.startDate),
      endDate: new Date(this.branchLoansReportModel.endDate),
      idNumber: this.branchLoansReportModel.idNumber,
    });
  }

  // get idNumber() {
  //   return this.lexisNexisReportForm.get("idNumber");
  // }
  searchByDates() {
    if (this.lexisNexisReportForm.controls?.startDate.invalid || this.lexisNexisReportForm.controls?.endDate.invalid) {
      this.global_utility.error('Invalid dates provided!');
      return;
    }

    this.branchLoansReportModel = new BranchLoansReportModel();
    // this.branchLoansReportModel.startDate = this.lexisNexisReportForm.controls?.startDate.value?.toJSON();
    // this.branchLoansReportModel.endDate = this.lexisNexisReportForm.controls?.endDate.value?.toJSON();
    this.branchLoansReportModel.startDate = this.startDate; // lexisNexisReportForm.controls?.startDate.value?.toJSON();
    this.branchLoansReportModel.endDate = this.endDate;


    this.lstLexisNexis = [];
    this.reportService
      .pullLexisnexisReport(this.branchLoansReportModel)
      .subscribe({
        next: (result) => {
          this.lstLexisNexis = result;
        },
        error: (err) => {},
      });
  }

  searchByIdNumber() {
    if (this.lexisNexisReportForm.controls?.idNumber.invalid) {
      this.global_utility.error('Invalid dates provided!');
      return;
    }

    this.branchLoansReportModel = new BranchLoansReportModel();
    this.branchLoansReportModel.idNumber = this.idNumber;
    this.lstLexisNexis = [];
    
    this.reportService
      .pullLexisnexisReport(this.branchLoansReportModel)
      .subscribe({
        next: (result) => {
          this.lstLexisNexis = result;
     },
        error: (err) => {},
      });
  }

  exportToExcel(): void {
    console.log('exportToExcel');
    this.title = 'export-excel';
    this.fileName = 'Lexis Nexis: ' + new Date().toLocaleDateString() + '.xlsx';

    /* pass here the table id */
    //const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.lstLexisNexis);
    const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(this.table.nativeElement);
    /* generate workbook and add the worksheet */

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */

    XLSX.writeFile(wb, this.fileName);
  }


}
