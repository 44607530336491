import { MdbModalModule, MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { DatePipe, CommonModule } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, OnDestroy, Output, TrackByFunction, ViewChild } from '@angular/core';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Subject, Subscription } from 'rxjs';
import { QuoteStoreService } from 'src/app/core/rest-services/quote-store.service';
import { LmsPaginateComponent } from '@lmsShared';
import { NgxPaginationModule, PaginationInstance } from 'ngx-pagination';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';

@Component({
  selector: 'app-audit-trail',
  templateUrl: './audit-trail.component.html',
  styleUrls: ['./audit-trail.component.css'],
  standalone: true,
  imports: [ CommonModule, MatTableModule, MatPaginatorModule, MdbModalModule, DatePipe, LmsPaginateComponent, NgxPaginationModule, MdbModalModule,
    LmsPaginateComponent,
    NgxPaginationModule],
})
export class AuditTrailComponent implements AfterViewInit, OnDestroy {
  @ViewChild('paginator') paginator: MatPaginator;

  @ViewChild(LmsPaginateComponent)
  set lmspaginate(v: LmsPaginateComponent) {
    this._lmsPaginate = v;
  }
  _lmsPaginate: LmsPaginateComponent;

  idNumber: string;
  quoteId: number;
  //PAGINATOR CODE
  config: PaginationInstance = {
    id: 'aud_pager',
    itemsPerPage: 5,
    currentPage: 1,
  };

  identity: TrackByFunction<any> = (_, item: any) => item.time;

  listArray: any;
  selectedItem: any;
  sum = 20;
  direction = '';
  isauditLoading = false;

  @Output() onRowSelect = new EventEmitter<any>();

  @Output() onRowAdd = new EventEmitter<any>();
  @Output() onRowEdit = new EventEmitter<any>();
  @Output() onLoaded = new EventEmitter<number>();

  displayed_columns!: string[];
  private data_change_sub!: Subscription;
  table_data_source = new MatTableDataSource<any>([]);

  updated_row_index = -1;
  table_config: any = {
    columns: [
      {
        key: 'time',
        heading: 'Date',
        datecol: true,
      },
      {
        key: 'userName',
        heading: 'User',
        hideCol: true,
      },
      {
        key: 'action',
        heading: 'Action',
        hideCol: true,
      },
      {
        key: 'newData',
        heading: 'New Data',
      },
      {
        key: 'previousData',
        heading: 'Previous Data',
      },
    ],
    primary_key_set: ['time'],
    table_data_changer: new Subject<any>(),
    ediTable: {
      add: false,
      edit: false,
    },
  };

  constructor(
    public modalRef: MdbModalRef<AuditTrailComponent>,
    private quoteStoreService: QuoteStoreService
  ) {}

  ngAfterViewInit(): void {
    this.setDisplayedColumns(this.table_config.columns);
    this.isauditLoading = true;

    this.data_change_sub = this.quoteStoreService
      .searchAuditTrailByQuoteId(this.quoteId)
      .subscribe((table_data) => {
        this.table_config.table_data_changer.next(table_data);
        this.isauditLoading = false;
      });

    if (this.table_config.table_data_changer) {
      // if there is a scope to update data
      this.trackDataChange();
    }

    this.table_data_source.paginator = this.paginator;
  }

  setDisplayedColumns(column_config: any[]) {
    const col_count = column_config.length;
    const columns_to_display: any[] = [];
    for (let i = 0; i < col_count; i++) {
      if (!column_config[i].hideCol) {
        const col_config = column_config[i];
        columns_to_display.push(col_config.key);
      }
    }
    this.displayed_columns = columns_to_display;
  }

  setPage(page: number) {
    // get new pager object for specified page
    // get new page of items from items array
    // if (Array.isArray(this.items)) {
    //   const pageOfItems = this.items.slice(
    //     this.pager.startIndex,
    //     this.pager.endIndex + 1,
    //   );
    this.paginator.pageIndex = page-1;
    this.paginator['page'].emit();
    //   // call change page function in parent component
    //   //this.changePage.emit(this.table_data_source);
    // }
  }

  trackDataChange() {
    this.data_change_sub = this.table_config.table_data_changer.subscribe(
      (new_data: any) => {
        //this.table_data_source = new_data;
        this.table_data_source.data = new_data;
        this.table_data_source.paginator = this.paginator;
        if (new_data.highlight) {
          //if it is needed to highlight the updated/new row
          this.goToUpdatedPage(new_data.highlight, new_data.data);
        }
        this.onLoaded.emit(new_data.length);
        console.log('onLoaded emit = ' + new_data.length);
      },
    );
  }

  goToUpdatedPage(updated_row: any, data: any[]) {
    //get the index of the updated row
    const updated_index = data.findIndex((row) => {
      let is_matching = true;
      const primary_key_count = this.table_config.primary_key_set.length;
      for (let i = 0; i < primary_key_count; i++) {
        const column = this.table_config.primary_key_set[i];
        if (row[column] != updated_row[column]) {
          is_matching = false;
          break;
        }
      }
      return is_matching;
    });
    //get the page the updated row is and navigate to it after 1sec
    setTimeout(() => {
      if (updated_index >= 0) {
        //const page_size = this.config.itemsPerPage;
        //const current_page_index = this.config.currentPage;
        // const calculated_page_index =
        //   Math.ceil((updated_index + 1) / page_size) - 1;
        // if (calculated_page_index != current_page_index) {
        //   if (calculated_page_index == 0) {
        //     //if the first page is to be navigated to
        //     //this.pager.pageIndex = 1;
        //   } else {
        //     //this.pager.pageIndex = calculated_page_index - 1;
        //     this.config.currentPage = calculated_page_index - 1;
        //     //this.paginator.nextPage();
        //   }
        // }
        //this.updated_row_index =
        //  updated_index - page_size * calculated_page_index;
        setTimeout(() => {
          this.updated_row_index = -1;
        }, 1000);
      }
    }, 100);
  }

  ngOnDestroy(): void {
    this.data_change_sub!.unsubscribe();
  }

  close() {
    this.modalRef.close();
  }
}
