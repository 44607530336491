<div class="modal-content">
  <div class="modal-body">
    <div class="center-screen">
      <form [formGroup]="confirmedForm" class="mx-2 py-0" ngNativeValidate>
        <table id="employConfirm" class="table table-striped table-sm">
          <caption>
            Loan Details
          </caption>

          <tbody>
            <tr>
              <td>
                <p class=" text-center text-wrap">
                  <b>I confirm that I have contacted the client's employer,
                    and that the client is employed at the given employer.</b>
                </p>
              </td>
            </tr>

            <tr>
              <td>
                <div class="center-screen py-2">
                  <label for="empConfirmed">Is employment confirmed?</label><br>
                  <mat-slide-toggle color="primary" formControlName="empConfirmed" class="mx-3 my-2 py-2">
                  </mat-slide-toggle>
                  <p><strong>{{confirmedForm!.value.empConfirmed ? 'Confirmed employment!' :
                      'Employment NOT confirmed!'}}</strong></p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </div>
    <div class="flex-container center-screen py-0 my-0">
      <button class="btn btn-primary" (click)="close()">Cancel</button>
      <button class="btn btn-primary " mdbRipple (click)="save()">Submit</button>
    </div>
  </div>
</div>
