import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { NgxPaginationModule, PaginationInstance } from 'ngx-pagination';
import { LMSDialogHandlerService } from '../../lmsdialog-handler.service';
import { FormBuilder, FormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppUtilityService } from 'src/app/app-utility.service';
import { BranchLoansReportComponent } from '../branch-loans-report/branch-loans-report.component';
import { CommonModule } from '@angular/common';
import { ZARcurrencyPipe } from '../../pipes/zarcurrency.pipe';
import { LmsPaginateComponent } from '../../ui/lms-paginate/lms-paginate.component';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-branch-performance-report',
  templateUrl: './branch-performance-report.component.html',
  styleUrls: ['./branch-performance-report.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ZARcurrencyPipe,
    NgxPaginationModule,
    LmsPaginateComponent
  ],
})
export class BranchPerformanceReportComponent {

  lstPerformanceResults: Array<any> = [];
  title = 'export-excel';
  fileName = '';
  @ViewChild('TABLE') table: ElementRef;
  //displayedColumns = ['position', 'name', 'weight', 'symbol'];
  dataSource = this.lstPerformanceResults;
  config: PaginationInstance = {
    id: 'pager',
    itemsPerPage: 15,
    currentPage: 1,
  };

  constructor(
    // private route: ActivatedRoute,
    // private clientSearch: ClientDetailsService,
    // public quoteStoreService: QuoteStoreService,
    public lmsDialogHandler: LMSDialogHandlerService,
    public global_utility: AppUtilityService,
    public dialogRef: MatDialogRef<BranchPerformanceReportComponent>,
    private formbuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) data: any
  ) { 
    this.lstPerformanceResults = data.performanceList
    //isReadOnly: true
  }

  exportLoansToExcel(): void {
    this.title = 'export-excel';
    this.fileName = 'Branch Report: ' + new Date() + '.xlsx';

    /* pass here the table id */
    //const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.lstQuotes);
    // table_to_sheet(this.lstQuotes);
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      this.table.nativeElement,
    );

    /* generate workbook and add the worksheet */

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */

    XLSX.writeFile(wb, this.fileName);
  }

  close() {
    this.dialogRef!.close();
    //return this.dialogRef.close(this.employmentDetailsForm.value);
  }


}
