<div class="row center-screen mx-4 my-1">
  <table w550 id="cbsLogin" class="table table-striped table-fixed table-sm  shadow-2-strong rounded-1">
    <caption>Log into CBS</caption>
    <tbody style="height: 100px;" class="center-screen">
      <tr>
        <td><b>1. Log in to CBS to transfer the funds to the client's bank account.</b></td>
      </tr>
      <tr>
        <td><b>2. Please use the statement reference number when transferring the funds.</b></td>
      </tr>
      <tr>
        <td><b>3. Please pay out the amount reflected in the Disbursed Amount field.</b></td>
      </tr>
    </tbody>
  </table>
  <button class="btn btn-primary my-2" mdbRipple type="button" (click)="close()">Close</button>
</div>
