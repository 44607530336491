<div class="modal-content">

  <section>
    <div class="center-screen" #TABLE>
      <table mat-table #table class="table table-striped table-divider-color table-hover table-sm mb-3 caption-top"
        style="width: 400px">

        <ng-container matColumnDef="position">
          <th mat-header-cell *matHeaderCellDef> No. </th>
          <td mat-cell *matCellDef="let element"> {{element.position}} </td>
        </ng-container>

        <caption>Statistics by selected dates </caption>
        <thead>
          <tr>
            <th scope="col" style="width: 200px" align="center">Loan Status</th>
            <th scope="col" style="width: 200px" align="center">Loan Count </th>
          </tr>
        </thead>
        <tbody class="table-group-divider table-divider-color">
          <tr *ngFor="let stats of lstStats">
            <td style="width: 200px" align="center">{{stats.loanStatus}}</td>
            <td style="width: 200px" align="center">{{stats.loanCount}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="my-3">
      <!-- <button mat-raised-button class="btn btn-primary btn-rounded me-2" color="primary"
                (click)="exportLoansToExcel()">Export full report to Excel</button> -->

      <button class="btn btn-primary me-2" type="button" (click)="close()">Close</button>
    </div>
  </section>


</div>
