<div class="modal-content">
  <div class="modal-body">
    <div class="container-fluid ">
      <div class="card">
        <div class="card-body p-1">
          <div class="row center-screen my-2 mb-0">
            <div *ngIf="isauditLoading" class="fade show" style="text-align: center; height: 30px">
              <i class="spinner-grow spinner-grow-sm"></i>
              <span class="ms-2">Loading quote logs, please wait!</span>
            </div>
            <ng-container [style.display]="!isauditLoading ? 'block' : 'none'">
              <div class="center-screen">
                <table mat-table [dataSource]="table_data_source!" [trackBy]="identity"
                  class="table table-striped table-auto table-sm mb-1" color="primary">
                  <ng-container *ngFor="let column of table_config.columns; index as i" [matColumnDef]="column.key">
                    <th mat-header-cell *matHeaderCellDef [class.numeric-col]="column.numeric">{{column.heading}}</th>
                    <td mat-cell *matCellDef="let table_row" [class.numeric-col]="column.numeric"
                      [class.dataval-col]="column.key === 'newData' || column.key === 'previousData'"
                      [class.date-col]="column.datecol">

                      <ng-container *ngIf="column.datecol">
                        <figure>
                          <blockquote class="blockquote lh-sm">
                            <p class="mb-2 text-muted">{{table_row[column.key] | date: 'yyyy-MM-dd HH:mm:ss'}}</p>
                            <h6 class="mb-1 text-end">{{table_row['action']}}</h6>
                          </blockquote>
                          <figcaption class="blockquote-footer">
                            <cite title="Actioning User">{{table_row['userName']}}</cite>
                          </figcaption>
                        </figure>
                      </ng-container>

                      <ng-container *ngIf="column.key === 'newData'">
                        <div class="note note-success opacity-95">
                          <strong class="text-success"><i class="fas fas fa-circle-check me-2"></i> New: </strong>
                          <div class="ps-2 text-break lh-sm">{{table_row[column.key]}}</div>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="column.key === 'previousData' && table_row[column.key]">
                        <div class="note note-danger opacity-75">
                          <strong class="text-danger"
                            [ngClass]="{'text-decoration-line-through': !table_row[column.key]}"><i
                              class="fas fa-circle-xmark me-2"></i> Previous: </strong>
                          <div class="ps-1 text-break lh-sm">{{table_row[column.key]}}</div>
                        </div>
                      </ng-container>
                    </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayed_columns" class="table-center-header"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayed_columns; let i = index;">
                  </tr>
                </table>
                <lms-paginate [config]="config" (changePaged)="setPage($event)"></lms-paginate>
                <mat-paginator #paginator [pageSize]="config.itemsPerPage" style="display: none;">
                </mat-paginator>
                <ul style="display: none;">
                  <li *ngFor="let item of table_data_source.data | paginate: config">
                  </li>
                </ul>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
