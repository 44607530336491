<div class="modal-content">
  <div class="modal-header">
    <div class="modal-title">
      <app-page-cardhead style="margin-top: -1.2rem;" [PageName]="'Document Upload'"
        [PageHeadWidth]=700></app-page-cardhead>
    </div>
    <button class="btn btn-close" (click)="dialogRef.close()"></button>
  </div>

  <div class="modal-body">
    <div class="container-fluid">
      <div class="row center-screen">
        <table id="clientHeader" w800 class="table p-1 table-striped table-sm mb-2 shadow-2-strong rounded-1">
          <caption>Client Details</caption>
          <tbody class="field-4column-label">
            <tr>
              <td>First names</td>
              <td>{{clientDetails.firstName}}</td>

              <td>Surname</td>
              <td>{{clientDetails.lastName}}</td>
            </tr>

            <tr>
              <td>ID number</td>
              <td>{{clientDetails.idNumber}}</td>

              <td>Cell number</td>
              <td>{{clientDetails.cellNumber}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="card">
        <div class="card-body p-1">
          <div class="row center-screen my-2">
            <!--
            NOTE TO ALL DEVELOPERS. DO NOT CHANGE ANYTHING ON THIS COMPONENT!!!
          -->
            <div class="d-flex justify-content-center mt-1 mb-2">
              <table w1000 class="table table-striped p-1 table-auto table-sm shadow-2-strong rounded-1">
                <caption>Document List</caption>
                <thead>
                  <tr>
                    <th>Document</th>
                    <th>File name</th>
                    <th>Upload date</th>
                    <th>Uploaded by</th>
                    <th>View</th>
                    <th *ngIf="!isReadOnly">Delete</th>
                  </tr>
                </thead>

                <tbody>
                  <tr *ngFor="let doc of lstDocuments | paginate: config;">
                    <td>{{doc.documentType}}</td>
                    <td style="text-overflow: ellipsis;">{{doc.originalFileName}}</td>
                    <td style="width: 130px;">{{covertDate(doc.uploadDate)}}</td>
                    <td style="width: 135px;">{{doc.userName}}</td>
                    <td style="width: 40px;">
                      <button class="btn btn-floating btn-icon fs-5 fas" mdbRipple
                        (click)="onDownloadPdf(doc.fileName)">
                        <i class="fas fa-sm fa-fw fa-eye"></i>
                      </button>
                    </td>
                    <td *ngIf="!isReadOnly" style="width: 40px;">
                      <span *ngIf="!(doc.documentType === 'CODIX REPORT')">
                        <button class="btn btn-floating btn-icon fs-5 fas" mdbRipple (click)="deleteDocument(doc)">
                          <i class="fas fa-xs fa-trash-can"></i>
                        </button>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <lms-paginate [config]="config"></lms-paginate>
            </div>

            <div *ngIf="!isReadOnly" class="row mt-1 center-screen">
              <table w700 class="table table-striped table-hover table-sm shadow-1-strong rounded-1">
                <caption>Document Upload</caption>
                <tbody>
                  <tr>
                    <td>
                      <select mdbInput class="form-control form-select me-1 lmsselect" [(ngModel)]="selectedDocType"
                        style="width: 210px;" (ngModelChange)="onReqDocSelect($event)" required>
                        <option value="">-- Select --</option>
                        <option *ngFor="let doc of requiredDocs" [ngValue]="doc">{{doc.documentName}}</option>
                      </select>
                    </td>

                    <td>
                      <div class="input-group p-1">
                        <input mdbInput type="file" class="form-control shadow-1-strong align-content-around py-2"
                          id="inputGroupFile02" aria-describedby="inputGroupFileAddon02"
                          (change)="onFileSelected($event)" accept="application/pdf" #fileSelector
                          aria-label="Submit" />
                        <button class="btn btn-primary" mdbWavesEffect type="button" id="inputGroupFileAddon02"
                          (click)="onDocumentSubmit()">
                          Submit
                        </button>
                      </div>
                    </td>
                    <!-- <td>
                <button type="button" class="btn btn-primary mdbWavesEffect class="btn btn-primary form-file-button"  (click)="onDocumentSubmit()"
                  [disabled]="false">
                  <div class="submitBtn"> Submit </div>
                </button>
              </td> -->
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="card-footer flex-container">
              <button class="btn btn-primary" mdbRipple (click)="close()"> Close </button>
              <button *ngIf="clientStatus === 'REPEAT' " class="btn btn-primary ms-3" type="button"
                (click)="confirmDocumentScanningDetails()">
                <i class="fas fa-thumbs-up fa-fw me-1"></i>
                Confirm </button>
            </div>
          </div>
        </div>
      </div>
    </div>
