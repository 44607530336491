<ng-container>
  <!-- <mat-drawer-container class="customer-container" [hasBackdrop]="false">
    <mat-drawer #drawer [mode]="'side'" [position]="'end'" (openedStart)="openNoteDialog()">
          <client-notes [clientid]="clientDetails.clientId" (clientNoteCount)="setnotesCount($event)"></client-notes>
    </mat-drawer>
    <mat-drawer-content> -->
  <div class="ms-auto me-auto align-content-center">
    <table w800 class="table table-striped table-responsive table-fixed table-sm mt-2 shadow-2-strong rounded-1">
      <ng-container>
        <div *ngIf="isNotesShowing; then showdrawer else hidedrawer"></div>
        <ng-template #showdrawer>
          <caption>
            <div class="d-flex align-items-center">
              <span class="ms-auto justify-content-around">Customer Detail</span>
              <div class="ms-auto justify-content-end" style="cursor: pointer;" (click)="openNoteDialog()">
                <button mat-icon-button class="btn btn-secondary btn-rounded" mdbRipple>
                  <i class="d-inline-flex fas far fa-comment fa-md"></i>
                  <span class="badge rounded-pill badge badge-notification bg-danger">{{notesCount | number}}</span>
                </button>
              </div>
            </div>
          </caption>
        </ng-template>
        <ng-template #hidedrawer>
          <caption> Customer Detail </caption>
        </ng-template>
      </ng-container>
      <tbody class="field-4column-label">
        <tr *ngIf="!showQuote">
          <td colspan="4" class="text-center">Application: {{clientDetails?.id}}</td>
        </tr>
        <tr>
          <td>
            <label for="firstName">Full name(s)</label>
          </td>
          <td>
            {{clientDetails?.fullNames}}
          </td>
          <td>
            <label for="lastName">Surname</label>
          </td>
          <td>
            {{clientDetails?.surname}}
          </td>
        </tr>
        <!-- ID number -->
        <tr>
          <td>
            <label for="idNumber">ID number</label>
          </td>
          <td>{{clientDetails?.idNumber}}</td>
          <td>
            <label for="cellNumber">Cell number</label>
          </td>
          <td>{{clientDetails?.cellNumber}}</td>
        </tr>
        <tr>
          <td>
            <label for="clientType">Client type</label>
          </td>
          <td>{{clientDetails?.clientType}}</td>
          <td>
            <label for="clientNumber">Client number</label>
          </td>
          <td>{{clientDetails?.clientNumber||''}}</td>
        </tr>
        <tr *ngIf="showQuote">
          <td>
            <label for="id">Application number</label>
          </td>
          <td>{{clientDetails?.id}}</td>
          <td>
            <label for="loanPurpose">Loan purpose</label>
          </td>
          <td>{{clientDetails?.loanPurpose}}</td>
        </tr>
        <tr *ngIf="showQuote">
          <td>
            <label for="Agent">Consultant</label>
          </td>
          <td>{{clientDetails?.createdBy || ''}}</td>
          <td>
            <label for="branchName">Branch Name</label>
          </td>
          <td> {{clientDetails?.branchName}}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <!-- </mat-drawer-content>
  </mat-drawer-container> -->
</ng-container>
