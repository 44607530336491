<div class="modal-header">
  <div class="modal-title">
    <app-page-cardhead [PageName]="'Compuscan Offers'" [PageHeadWidth]="500"></app-page-cardhead>
  </div>
</div>

<div class="modal-body">
  <div class="card">
    <div class="card-body p-1">
      <div class="d-flex center-screen">
        <table w650 class="table table-auto table-bordered  table-striped table-sm shadow-2-strong rounded-1 p-1">
          <thead>
            <tr class="table-center-header">
              <th>Product</th>
              <th>Outcome</th>
              <th>Reason</th>
            </tr>
          </thead>
          <tbody>
            <!-- *ngIf="clientResults.loans"> -->
            <tr *ngFor="let compOffers of compuscanOffers">
              <td class="text-start">{{compOffers.description}}</td>
              <ng-container *ngIf="!!compOffers.outcome">
                <td class="text-center">
                  <span *ngIf="compOffers.outcome === 'Y'" class="badge badge-success rounded-pill d-inline">
                    {{compOffers.outcome}}
                  </span>
                  <span *ngIf="compOffers.outcome === 'N'" class="badge badge-danger rounded-pill d-inline">
                    {{compOffers.outcome}}
                  </span>
                </td>
              </ng-container>
              <td class="text-start">{{compOffers.reason}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="card-footer flex-container center-screen">
      <button class="btn btn-primary w-50" mdbRipple (click)="close()">
        Close
      </button>
    </div>
  </div>
</div>
