import { NgFor } from "@angular/common";
import { Component, ViewEncapsulation } from "@angular/core";
import { MdbModalRef } from "mdb-angular-ui-kit/modal";
import { PageCardheadComponent } from "@lmsShared";

@Component({
  selector: "app-risk-matrix-score",
  standalone: true,
  template: `
  <div class="modal-header">
  <div class="modal-title">
    <app-page-cardhead [PageName]="'Risk Analysis'" [PageHeadWidth]="500"></app-page-cardhead>
  </div>
</div>
  <div class="modal-body" >
    <div class="container-fluid">
    <div class="card">
      <div class="card-body p-1">
        <table w450 class="table table-bordered table-striped table-sm shadow-2-strong rounded-1 p-1" >
        <thead>
          <tr  class="table-center-header">
            <th>Risk Type</th>
            <th>Risk Score</th>
          </tr>
        </thead>
        <tbody> <!--  *ngIf="clientResults.loans"> -->
          <tr *ngFor="let risk of riskAnalysis">
            <td style="width: 70%;">{{risk.riskType}}</td>
            <td class="text-center muted-text" style="width: 25%;">{{risk.riskValue}}</td>
          </tr>
        </tbody>
        </table>
      </div>
      <div class="card-footer flex-container">
        <button
          class="btn btn-primary"
          mdbRipple
          (click)="close()">
          Close
        </button>
      </div>
    </div>
    </div>
</div>
`,
  imports: [NgFor, PageCardheadComponent],
  styleUrls: ["./risk-matrix-score.component.css"],
  encapsulation: ViewEncapsulation.Emulated
})
export class RiskMatrixScoreComponent {
  riskAnalysis: any;

  constructor(public modalRef: MdbModalRef<RiskMatrixScoreComponent>) { }


  close() {
    this.modalRef.close();
  }
}
