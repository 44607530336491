import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Subject } from 'rxjs';
import { UserService } from 'src/app/core/services/lmsuser.service';
import { QuoteStoreService } from 'src/app/core/rest-services/quote-store.service';
import { LMSNote } from 'src/app/core/models/lms-notes.model';
import { environment } from "src/environments/environment";
import { MdbRippleModule } from 'mdb-angular-ui-kit/ripple';
import { MatToolbarModule } from '@angular/material/toolbar';
import { CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';
import { PageCardheadComponent } from '../../shared/ui/pageheader-card/pageheader-card.component';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';

@Component({
  selector: 'lms-quote-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.css'],
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MatToolbarModule, MdbRippleModule, MatTableModule, PageCardheadComponent, CdkDrag, CdkDragHandle, ]
})
export class NotesComponent implements OnInit, AfterViewInit {
  isNotesShowing = environment.showClientNotes || false;
  @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
    console.log('event:', event);
    const count = this.table_data_source.length;
    event.returnValue = count;
  }

  quoteNoteForm = this.fb.group({
    quoteId: [0, [Validators.required]],
    noteStr: ['', [Validators.required]],
    userName: ['', [Validators.required]],
  });

  private _quoteid: number;
  public isNotesOpened = false;
  @Output() quoteNoteCount = new EventEmitter<number>();
  table_config: any = {
    columns: [
      {
        key: 'createdBy',
        heading: 'User',
        hideCol: false
      },
      {
        key: 'createdOn',
        heading: 'Date',
        datecol: false
      },
      {
        key: 'noteStr',
        heading: 'Note',
        hideCol: false
      },
    ],
    primary_key_set: ['time'],
    table_data_changer: new Subject<any>(),
    ediTable: {
      add: false,
      edit: false
    }
  };
  existingNotes:LMSNote[] = [];
  savingNote = false;

  constructor(private fb: FormBuilder,
    private quoteNoteService: QuoteStoreService,
    private userservice: UserService,
    public dialogRef: MdbModalRef<NotesComponent>) {
  }

  ngOnInit() {
    this.setDisplayedColumns(this.table_config.columns);
    this.dialogRef.onClose.subscribe(_ => {
      const cou = this.table_data_source.data.length;
      this.dialogRef.close(cou);
    });
  }

  ngAfterViewInit() { }

  displayed_columns!: string[];
  table_data_source!: any;

  setDisplayedColumns(column_config: any[]) {
    const col_count = column_config.length;
    const columns_to_display: any[] = [];
    for (let i = 0; i < col_count; i++) {
      if (!column_config[i].hideCol) {
        const col_config = column_config[i];
        columns_to_display.push(col_config.key);
      }
    }
    this.displayed_columns = columns_to_display;
  }
  get noteStr() {
    return this.quoteNoteForm.controls['noteStr'];
  }

  public trackByFn(index, item) {
    if (!item) return null;
    return item.id;
  }

  closeDialog() {
    this.dialogRef.close();
  }

  addquoteNote() {
    this.savingNote = true;
    if (this.quoteNoteForm.invalid) {
      return;
    }
    this.quoteNoteService.addQuoteNote(this.quoteNoteForm.value).subscribe((noteres: any) => {
      this.existingNotes.push(noteres);
      this.table_data_source = new MatTableDataSource<any>(this.existingNotes);
      this.resetNotesForm();
      this.quoteNoteCount.emit(this.existingNotes.length);

      this.savingNote = false;
    });
  }

  private resetNotesForm() {
    this.quoteNoteForm.reset();
    this.quoteNoteForm.get('quoteId')?.patchValue(this.quoteid);
    this.quoteNoteForm.get('userName')?.patchValue(this.userservice.userLoginName);
    this.quoteNoteForm.updateValueAndValidity();
  }

  openNotes() {
    this.isNotesOpened = true;
    return this.isNotesOpened;
  }

  closeHideNotes() {
    this.isNotesOpened = false;
    return this.isNotesOpened;
  }

  public get quoteid(): number {
    return this._quoteid;
  }

  @Input() set quoteid(value: number | 0) {
    if (value && value > 0) {
      this._quoteid = value;

      this.quoteNoteService.getQuoteNotes(this.quoteid).subscribe(
        (cnote: any) => {
          this.existingNotes.push(cnote);
          this.table_data_source = new MatTableDataSource<any>(this.existingNotes);
          this.resetNotesForm();
          this.quoteNoteCount.emit(cnote.length);
        }
      );
    }
  }
}
