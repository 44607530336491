<div class="modal-content">
  <div class="modal-header">
    <div class="modal-title">
      <app-page-cardhead style="margin-top: -1.2rem;" [PageName]="'Client Documents'"
        [PageHeadWidth]=600></app-page-cardhead>
    </div>
    <button class="btn btn-close" color="red" aria-label="close dialog" (click)="dialogRef.close()"></button>
  </div>

  <div class="modal-body">
    <div class="container-fluid ">
      <div class="row center-screen">
        <app-client-header #clientHeaderComponent [clientDetails]="clientShow" [showQuote]="!isReadOnly"
          style="width:800px"></app-client-header>
      </div>
      <div class="card">
        <div class="card-body p-1">
          <div class="row center-screen my-2 mb-0">
            <div *ngIf="isDocumentLoading" class="fade show my-1" style="text-align: center; height: 30px">
              <i class="spinner-grow spinner-grow-sm"></i>
              <span class="me-2"> Loading documents, please wait! </span>
            </div>
            <table *ngIf="!isDocumentLoading" w950
              class="table p-1 table-striped table-auto table-hover table-sm shadow-1-strong rounded-1">
              <caption>Document List</caption>
              <thead>
                <tr>
                  <th scope="col">Document</th>
                  <th>File name</th>
                  <th scope="col">Upload date</th>
                  <th scope="col">Uploaded by</th>
                  <th scope="col" style="width: 40px;">View</th>
                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let doc of lstDocuments | paginate: config">
                  <td>{{doc.documentType}}</td>
                  <td style="text-overflow: ellipsis;">{{doc.originalFileName}}</td>
                  <td>{{covertDate(doc.uploadDate)}}</td>
                  <td>{{doc.userName}}</td>
                  <td>
                    <button class="btn btn-floating btn-icon fs-5 fas" mdbRipple (click)="onDownloadPdf(doc.fileName)">
                      <i class="fas fa-sm fa-fw fa-eye"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <lms-paginate class="center-screen" [config]="config"></lms-paginate>
          </div>
          <div *ngIf="isReadOnly" class="row center-screen mt-4 mb-5 ">
            <table w650 class="table table-striped table-auto table-sm shadow-2-strong rounded-1 p-1">
              <caption>Loan Summary Upload</caption>
              <tbody>
                <tr>
                  <td>
                    <select mdbInput class="form-control form-select me-1 lmsselect" style="width: 210px;"
                      disabled="disabled">
                      <option>Loan Summary (unsigned)</option>
                    </select>
                  </td>

                  <td>
                    <div class="input-group p-1">
                      <input mdbInput type="file" class="form-control shadow-1-strong align-content-around py-2"
                        id="inputGroupFile02" aria-describedby="inputGroupFileAddon02" (change)="onFileSelected($event)"
                        accept="application/pdf" #fileSelector aria-label="Submit" />
                      <button class="btn btn-primary" mdbWavesEffect type="button" id="inputGroupFileAddon02"
                        (click)="onDocumentSubmit()">
                        Submit
                      </button>
                    </div>
                  </td>
                  <!-- <td>
                <button type="button" mdbBtn  mdbWavesEffect class="btn btn-primary form-file-button"  (click)="onDocumentSubmit()"
                  [disabled]="false">
                  <div class="submitBtn"> Submit </div>
                </button>
              </td> -->
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
