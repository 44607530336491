import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: 'input[lmsNoPaste]',
  standalone: true
})
export class LMS_NoPasteDirective {
  @Input() noPasteMessage = 'Do not paste on important information fields!';

  /**
    * Event handler for host's paste event
    * @param e
    */

  @HostListener('paste', ['$event']) onPaste(e:ClipboardEvent) {
    // get and validate data from clipboard
    // const value = e!.clipboardData?.getData('text/plain');
    //this.validateValue(value);
    e.preventDefault();
  }
}
