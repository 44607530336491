/* eslint-disable @typescript-eslint/no-empty-function */
import { Directive, forwardRef, HostListener, Renderer2, ElementRef } from '@angular/core';
import { DefaultValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: 'input[lmsNoSpecials]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => LMS_NoSpecialsDirective),
    },
  ],
  standalone: true,
})
export class LMS_NoSpecialsDirective extends DefaultValueAccessor {
  constructor(private renderer: Renderer2, private el: ElementRef) {
    super(renderer, el,false);
  }

  private validateValue(value: string): void {
    const formattedVal = value.replace(/[^a-zA-Z0-9  _-]/gi, '');
    this.renderer.setProperty(this.el.nativeElement, 'value', value);
    this.writeValue(formattedVal);
  }

  @HostListener('paste', ['$event'])
  onPaste(e: ClipboardEvent) {
    // get and validate data from clipboard
    const clipvalue = e!.clipboardData?.getData('text/plain');


    this.validateValue(clipvalue!);
    e.preventDefault();
  }

  @HostListener('input', ['$event'])
  onInput(e: InputEvent) {
    const target = e.target as HTMLInputElement | null;
    if (target) {
      this.validateValue(target.value);
    }
  }

  override writeValue(value: any): void {
    if (this.el.nativeElement) {
      this.el.nativeElement.value = value;
    }
  }

  // private omit_special_char(event: any) {
  //   const k = event.charCode; //         k = event.keyCode;  (Both can be used)
  //   return (
  //     (k > 64 && k < 91) ||
  //     (k > 96 && k < 123) ||
  //     k == 8 ||
  //     k == 32 ||
  //     (k >= 48 && k <= 57)
  //   );
  // }

}
