<div class="d-flex align-content-center justify-content-center">
  <div class="center-screen">
    <form [formGroup]="branchLoansForm">
      <div class="center-screen">
        <table id="branchLoansSearch" style="width:500px; border: 1px; border-width: 1px;">
          <tbody>
            <tr>
              <td>Branch Name</td>
              <td>
                <select class="lmsselect" mdbInput formControlName="branch" style="max-width:500px;"
                  (ngModelChange)="changeBranch($event)" [(ngModel)]="branchLoansReportModel.selectedBranch">
                  <!-- <option value="all">
                    -- All branches
                  </option> -->
                  <option *ngFor="let branch of activeBranches" [ngValue]="branch">
                    {{ branch }}
                  </option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div *ngIf="hasselectedBranch" class="center-section">
        <div class="d-block ms-auto me-auto flex-nowrap">
          <table id="adminSearch" class="table table-sm table-striped table-fixed shadow-2-strong rounded-1"
            [style.width.px]="600">
            <caption>Search filters for {{selectedBranch}}</caption>
            <tbody>
              <tr>
                <td>Loans by date range</td>
                <td style="width: 50%;">
                  <mat-form-field class="my-2 input-group-sm d-inline-flex" appearance="fill">
                    <mat-label><small>Enter a date range</small></mat-label>
                    <mat-date-range-input [rangePicker]="picker2">
                      <input class="not" placeholder="Start date" matStartDate
                        [(ngModel)]="branchLoansReportModel.startDate" formControlName="startDate">


                      <input class="not" placeholder="End date" matEndDate [(ngModel)]="branchLoansReportModel.endDate"
                        formControlName="endDate">
                    </mat-date-range-input>
                    <mat-hint>YYYY-MM-DD | YYYY-MM-DD</mat-hint>
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker2></mat-date-range-picker>
                  </mat-form-field>
                </td>
              </tr>

              <tr>
                <td align="center" colspan="2">
                  <button mat-raised-button class="btn btn-primary btn-rounded me-2" color="primary"
                    (click)="search()">Search
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </form>
  </div>
</div>


<br />
<div class="d-flex align-content-center justify-content-center">
  <div class="center-screen">
    <table mat-table *ngIf="lstBranchLoans.length > 0" w1200
      class="table table-striped table-hover table-sm mb-2 shadow-1-strong rounded-1">
      <caption>Search Results </caption>
      <thead>
        <tr>
          <th scope="col" style="width:80px;">Loan no.</th>
          <th scope="col" style="width:240px;">Full names</th>
          <th scope="col" style="width:100px;">ID number</th>
          <th scope="col" style="width:80px;">Client no.</th>
          <th scope="col" style="width:80px;">Loan ref.</th>
          <th scope="col" style="width:90px;">Capital</th>
          <th scope="col" style="width:50px;">Term</th>
          <th scope="col" style="width:80px;">Instalment</th>
          <th scope="col" style="width:100px;">Total repayable</th>
          <th scope="col" style="width:90px;">Product name</th>
          <th scope="col" style="width:100px;">Created on</th>
          <th scope="col" style="width:80px;">Loan status</th>
          <th scope="col" style="width:110px;">Branch name</th>
          <!-- <th scope="col" style="width:300px;">Nett income</th>
            <th scope="col" style="width:300px;">Max affordability</th>
            <th scope="col" style="width:300px;">Max installment</th> -->
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let loan of lstBranchLoans | paginate: config;">
          <!-- | paginate: { itemsPerPage: pageSize, currentPage: pager.currentPage };"> -->
          <td style="text-align: center; border: 1px;"> {{loan.id}}</td>
          <td style="text-align: left;">{{loan.firstName}} {{loan.lastName}}</td>
          <td style="text-align: center;">{{loan.idNumber}}</td>
          <td style="text-align: center;">{{loan.clientNumber}}</td>

          <td style="text-align: center;">{{loan.loanReference}}</td>
          <td style="text-align: left;">{{loan.capital | zaRcurrency}}</td>
          <!-- <td >{{loan.capital}}</td> -->
          <td style="text-align: center;">{{loan.term}}</td>
          <td style="text-align: left;">{{loan.installment | zaRcurrency}}</td>
          <td style="text-align: left;">{{loan.totalRepayment | zaRcurrency}}</td>
          <!-- <td >{{loan.installment}}</td>
            <td >{{loan.totalRepayment}}</td> -->
          <td style="text-align: center;">{{loan.productName}}</td>
          <td style="text-align: center;">{{loan.createdOn | date}}</td>
          <td style="text-align: center;">{{loan.loanStatus}}</td>
          <td style="text-align: center;">{{loan.branchName}}</td>
        </tr>
      </tbody>
    </table>
    <br />

    <lms-paginate [config]="config"></lms-paginate>
  </div>
</div>

<!-- hidden table that will be printed with more fields -->
<div class="d-flex align-content-center justify-content-center">
  <div class="center-screen" #TABLE>
    <table mat-table #table *ngIf="lstBranchLoans.length > 0" style="width: 1800px; border: 1px;" hidden="true">
      <caption>Search Results</caption>
      <thead>
        <tr>
          <th scope="col">Loan no</th>
          <th scope="col">Branch name</th>
          <th scope="col" style="width:100px;">Created on</th>
          <th scope="col" style="width:100px;">Completed on</th>
          <!-- <th scope="col">Duration</th> -->
          <th scope="col">Loan status</th>
          <th scope="col">First names</th>
          <th scope="col">Surname</th>
          <th scope="col" style="width:100px;">Cell number</th>
          <th scope="col">ID number</th>
          <th scope="col">Client number</th>
          <th scope="col">First DO date</th>
          <th scope="col">Capital</th>
          <th scope="col">Term</th>
          <th scope="col">Nett income</th>
          <th scope="col">Max affordability</th>
          <th scope="col">Max installment</th>
          <th scope="col">Loan reference</th>
          <th scope="col">My benefits</th>
          <th scope="col">Installment</th>
          <th scope="col">Initiation fee</th>
          <th scope="col">Total repayment</th>
          <th scope="col">Total interest & fees</th>
          <th scope="col">Product name</th>
          <th scope="col">Reject reason </th>
          <!-- <th scope="col">OPT Override</th> -->
          <th scope="col">Created by</th>
          <!-- <th scope="col">Employer TNR</th> -->
          <th scope="col">Employer name</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let loan of lstBranchLoans">
          <td>{{ loan.id}}</td>
          <td>{{ loan.branchName}}</td>
          <td>{{loan.createdOn | date}}</td>
          <td>{{loan.updatedOn | date}}</td>
          <!-- <td>{{loan.duration}}</td> -->
          <td>{{loan.loanStatus}}</td>
          <td>{{loan.firstName}}</td>
          <td>{{loan.lastName}}</td>
          <td>{{loan.cellNumber}}</td>
          <td>{{loan.idNumber}}</td>
          <td>{{loan.clientNumber}}</td>
          <td>{{loan.firstDebitDate | date}}</td>
          <!-- <td>{{loan.capital | zaRcurrency}}</td>
            <td>{{loan.term}}</td>
            <td style="text-align: right;">{{loan.nettIncome | zaRcurrency}}</td>
            <td style="text-align: right;">{{loan.maxAffordability | zaRcurrency}}</td>
            <td style="text-align: right;">{{loan.maxInstallment | zaRcurrency}}</td>
            <td>{{loan.loanReference}}</td>
            <td style="text-align: right;">{{loan.myBenefits | zaRcurrency}}</td>
            <td style="text-align: right;">{{loan.installment | zaRcurrency}}</td>
            <td style="text-align: right;">{{loan.initiationFee | zaRcurrency}}</td>
            <td style="text-align: right;">{{loan.totalRepayment | zaRcurrency}}</td>
            <td style="text-align: right;">{{loan.totalInterestAndFees | zaRcurrency}}</td> -->
          <td>{{loan.capital }}</td>
          <td>{{loan.term}}</td>
          <td style="text-align: right;">{{loan.nettIncome }}</td>
          <td style="text-align: right;">{{loan.maxAffordability }}</td>
          <td style="text-align: right;">{{loan.maxInstallment }}</td>
          <td>{{loan.loanReference}}</td>
          <td style="text-align: right;">{{loan.myBenefits }}</td>
          <td style="text-align: right;">{{loan.installment }}</td>
          <td style="text-align: right;">{{loan.initiationFee }}</td>
          <td style="text-align: right;">{{loan.totalRepayment }}</td>
          <td style="text-align: right;">{{loan.totalInterestAndFees }}</td>

          <td>{{loan.productName}}</td>
          <td>{{loan.rejectReason }}</td>
          <!-- <td>{{loan.OPT_Override}}</td> -->
          <td>{{loan.createdBy}}</td>
          <!-- <td>{{loan.employer_TNR}}</td> -->
          <td>{{loan.employerName}}</td>

          <!-- <td>{{loan.id}}</td>
            <td style="width:300px;">{{loan.firstName}} {{loan.lastName}}</td>
            <td>{{loan.idNumber}}</td>
            <td>{{loan.clientNumber}}</td>

            <td class="text-start text-md-start">{{loan.loanReference}}</td>
            <td style="width:150px;">{{loan.capital | zaRcurrency}}</td>
            <td>{{loan.term}}</td>
            <td>{{loan.offer.installment | zaRcurrency}}</td>
            <td>{{loan.offer.totalRepayable | zaRcurrency}}</td>
            <td>{{loan.offer.productName}}</td>
            <td>{{loan.createdOn | date}}</td>
            <td>{{loan.loanStatus}}</td>
            <td>{{loan.branchName}}</td>

            <td>{{loan.affordability.nettIncome | zaRcurrency}}</td>
            <td>{{loan.affordability.maxAffordability | zaRcurrency}}</td>
            <td>{{loan.affordability.maxInstallment | zaRcurrency}}</td> -->



          <!-- <td>
              <button mat-raised-button class="btn btn-primary btn-rounded" mdbWavesEffect color="primary" type="button"
                (click)="loanDetails(loan.id)">Loan Details</button>
            </td> -->
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div *ngIf="lstBranchLoans.length > 0">
  <button mat-raised-button class="btn btn-primary btn-rounded me-2" color="primary" (click)="exportToExcel()">
    Export full report to Excel</button>
</div>