import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MdbRippleModule } from 'mdb-angular-ui-kit/ripple';
import { MatCardModule } from '@angular/material/card';
import { PageCardheadComponent } from '@lmsShared';
import { BankDetailsModel, ClientShowModel } from '@lmsModels';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';

@Component({
  selector: 'app-bank-acc-list',
  templateUrl: './bank-acc-list.component.html',
  styleUrls: ['./bank-acc-list.component.css'],
  standalone: true,
  imports: [CommonModule, MatCardModule, MdbRippleModule, PageCardheadComponent]
})
export class BankAccListComponent implements OnInit {
  clientDetails: any;
  bankingDetails: any = {};
  bankDetailsModel: BankDetailsModel = new BankDetailsModel();
  readOnly = false;
  constructor(public dialogRef: MdbModalRef<BankAccListComponent>) { }

  ngOnInit(): void {
    console.log("Banking details laiata...");
  }

  onSelectAccount(accnt: any) {
    console.log(accnt);
    this.bankDetailsModel = accnt;

  }

  submitBankDetails() {
    return this.dialogRef.close(this.bankDetailsModel);
  }

  close() {
    return this.dialogRef.close();
  }
}
