<div class="modal-content">
  <div class="modal-header">
    <div class="modal-title">
      <app-page-cardhead style="margin-top: -1.2rem;" [PageName]="'Quote Notes'"
        [PageHeadWidth]="500"></app-page-cardhead>
    </div>
    <button class="btn btn-close" aria-label="close dialog" (click)="dialogRef.close()"></button>
  </div>

  <div class="modal-body">
    <div class="card m-1">
      <div class="card-body p-2">

        <form [formGroup]="quoteNoteForm">
          <div fxFlex class="note-capture-holder mat-elevation-z2">
            <mat-toolbar class="mat-elevation-z4" style="max-height: 300px">
              <table class="container-fluid input-container px-1 d-flex align-content-end justify-content-end">
                <tbody>
                  <td>
                    <input formControlName="noteStr" placeholder="Quote note" required>
                  </td>
                  <td>
                    <button [disabled]="savingNote" mat-raised-button class="btn btn-primary btn-rounded mx-1" mdbRipple
                      color="primary" (click)="addquoteNote()">
                      Add Note</button>
                  </td>
                </tbody>
                <!-- <div *ngIf="clientNoteForm.invalid && noteStr.errors?.required" class="error-label">
              <small class="text-danger">
                Note is required before saving
              </small>
            </div> -->
              </table>
            </mat-toolbar>
            <div fxLayout="row" class="ms-auto my-1 p-md-2 p-lg-3 p-1" fxLayoutAlign="start start" fxLayoutGap="10px">
              <table mat-table [dataSource]="table_data_source!"
                class="clientnote-list-wrapping table table-striped  table-hover table-sm caption-top">
                <ng-container *ngFor="let column of table_config.columns; index as i" [matColumnDef]="column.key">
                  <th mat-header-cell *matHeaderCellDef>{{column.heading}}</th>
                  <td mat-cell *matCellDef="let table_row">
                    <ng-container *ngIf="column.key === 'createdBy'">
                      <span class="text-emphasis text-start">
                        <div class="font-weight-bold"></div>{{table_row[column.key]}}
                      </span><br>
                      <span class="ms-auto text-emphasis text-end">
                        <small class="ms-auto text-muted text-end">
                          {{table_row['createdOn'] | date: 'yyyy-MM-dd HH:mm:ss'}}
                        </small>
                      </span>
                    </ng-container>
                    <!-- <ng-container *ngIf="column.key === 'createdOn'">
                <span class="text-emphasis text-end">
                  <small class="ms-auto text-muted text-end">{{table_row['createdOn'] | date: 'yyyy-MM-dd HH:mm:ss'}}</small>
                </span>
              </ng-container> -->
                    <ng-container *ngIf="column.key === 'noteStr'">
                      <div class="d-flex thisnote text-center note note-info">
                        <span class="list-note-item">
                          <div class="font-weight-bold">Note: </div>
                          {{table_row[column.key]}}
                        </span>
                      </div>
                    </ng-container>
                  </td>
                </ng-container>

                <!-- <tr mat-header-row *matHeaderRowDef="displayed_columns" class="table-center-header"></tr> -->
                <tr mat-row *matRowDef="let row; columns: displayed_columns; let i = index;">
              </table>
            </div>
          </div>

          <!-- <div class="form-group">
        <button mat-raised-button type="button" class="me-2" color="primary" (click)="closeDialog()"> Close </button>
      </div> -->
        </form>
      </div>
    </div>
  </div>
</div>
