import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AdminManagerSearchModel, BranchModel, RegionModel } from '@lmsModels';
import { BehaviorSubject, map, Observable, of, switchMap } from 'rxjs';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class BranchService {
  private _lstallBranches: Observable<BranchModel[]>;
  private _lstlmsRegions = new BehaviorSubject<RegionModel[]>([]);

  BranchAdmin_apiURL = environment.apiUrl + '/loan/api/branch';

  HttpParamsOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private http: HttpClient) { }

  saveBranch(branch: any) {
    return this.http
      .post<any>(this.BranchAdmin_apiURL + '/saveBranch', branch)
      .pipe(
        map((responseData) => {
          return responseData;
        })
      );
  }

  deleteBranch(branch: any) {
    return this.http
      .post<any>(this.BranchAdmin_apiURL + '/deleteBranch', branch)
      .pipe(
        map((responseData) => {
          return responseData;
        })
      );
  }

  saveArea(area: any) {
    return this.http
      .post<any>(this.BranchAdmin_apiURL + '/saveArea', area)
      .pipe(
        map((responseData) => {
          return responseData;
        })
      );
  }

  deleteArea(area: any) {
    return this.http
      .post<any>(this.BranchAdmin_apiURL + '/deleteArea', area)
      .pipe(
        map((responseData) => {
          return responseData;
        })
      );
  }

  saveRegion(region: any) {
    return this.http
      .post<any>(this.BranchAdmin_apiURL + '/saveRegion', region)
      .pipe(
        map((responseData) => {
          return responseData;
        })
      );
  }

  createRegion(region: any) {
    return this.http
      .post<any>(this.BranchAdmin_apiURL + '/createRegion', region)
      .pipe(
        map((responseData) => {
          return responseData;
        })
      );
  }

  deleteRegion(region: any) {
    return this.http
      .post<any>(this.BranchAdmin_apiURL + '/deleteRegion', region)
      .pipe(
        map((responseData) => {
          return responseData;
        })
      );
  }

  get lstBranchesObs() {
    return this._lstallBranches;
  }

  public get FinbondBranchList() {
    return this._lstallBranches.pipe(map(b => b.values));
  }

  getAllFinbondBranches() {
    return this.http.get<any>(`${this.BranchAdmin_apiURL}/getAllBranches`)
      .pipe(map(lst => { return lst; }));
  }

  getAllFinbondBranchNames() {
    return this.http
      .get<any>(`${this.BranchAdmin_apiURL}/getAllFinbondBranchNames`);
  }

  handleBracnhListLoad(value: BranchModel[]) {
    this._lstallBranches = of(value);
    return this._lstallBranches;
  }

  // getAllFinbondBranches() {
  //   return this.http
  //   .get<BranchModel[]>(`${this.BranchAdmin_apiURL}/getAllBranches`);
  // }

  getAllBranchNames() {
    return this.http.get<BranchModel[]>(`${this.BranchAdmin_apiURL}/getAllBranchNames`)
      .pipe(switchMap(lst => this.handleBracnhListLoad(lst)));
  }

  getAllAreas() {
    return this.http
      .get<any>(`${this.BranchAdmin_apiURL}/getAllAreas`);
  }

  getAllBranchesByRegion(region: any) {
    return this.http
      .get<any>(`${this.BranchAdmin_apiURL}/getAllBranchesByRegion/${region}`)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  getAllAreasByRegion(data: any) {
    return this.http
      .post<any>(`${this.BranchAdmin_apiURL}/getAllAreasByRegion`, data);
    // .pipe(
    //   map((res) => {
    //     return res;
    //   })
    // );
  }

  getAllRegions() {
    return this.http
      .get<RegionModel[]>(`${this.BranchAdmin_apiURL}/getAllRegions`)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  get lstLmsRegionsObs() {
    return this._lstlmsRegions.asObservable();
  }

  public get LMSRegionList() {
    return this._lstlmsRegions.value;
  }

  getAllLMSRegions() {
    return this.http.get<RegionModel[]>(`${this.BranchAdmin_apiURL}/getAllRegions`);
  }

  getAllBranchesByArea(area: any) {
    return this.http
      .post<any>(`${this.BranchAdmin_apiURL}/getAllBranchesByArea`, area)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  getBranchByName(branchName: any) {
    return this.http
      .post<any>(`${this.BranchAdmin_apiURL}/getBranchByName`, branchName)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  findLoansByDates(searchModel: any) {
    return this.http
      .post<any>(`${this.BranchAdmin_apiURL}/findLoansByDates`, searchModel)
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  findLoansByStatus(searchModel: any) {
    return this.http
      .post<any>(`${this.BranchAdmin_apiURL}/findLoansByStatus`, searchModel)
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  findLoansByIdNumber(searchModel: any) {
    return this.http
      .post<any>(`${this.BranchAdmin_apiURL}/findLoansByIdNumber`, searchModel)
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  findLoansByClientNumber(searchModel: any) {
    return this.http
      .post<any>(`${this.BranchAdmin_apiURL}/findLoansByClientNumber`, searchModel)
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  findLoanCountByDates(searchModel: any) {
    return this.http
      .post<any>(`${this.BranchAdmin_apiURL}/findLoanCountByDates`, searchModel)
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  findRegionLoanStats(searchModel: any) {
    return this.http
      .post<any>(`${this.BranchAdmin_apiURL}/findRegionLoanStats`, searchModel)
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }


  findRegionAreaLoanStats(searchModel: any) {
    return this.http
      .post<any>(`${this.BranchAdmin_apiURL}/findRegionAreaLoanStats`, searchModel)
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  branchPerformanceReport(searchModel: AdminManagerSearchModel) {
    return this.http
      .post<any>(`${this.BranchAdmin_apiURL}/branchPerformanceReport`, searchModel)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  // getDevicesByBranch(branchNo: any) {
  //   return this.http
  //     .get<any>(this.DebicheckDevices_apiURL + '/findDevicesByBranch/' + branchNo)
  //     .pipe(
  //       map((result: any) => {
  //         return result;
  //       })
  //     );
  // }

  // findEmployersByName(data: any) {
  //   return this.http
  //   .post<BranchEmployer[]>(this.UpdateEmployer_apiURL + '/findEmployersByName',data)
  //     .pipe(
  //       //catchError(this.handleError),
  //       map((responseData) => {
  //       return responseData;
  //     }));
  // }
  // getAllBranchesByRegion(region: any) {
  //   return this.http
  //     .get<any>(`${this.BranchAdmin_apiURL}/getAllBranchesByRegion/${region}`)
  //     .pipe(
  //       map((res) => {
  //         return res;
  //       })
  //     );
  // }


}

