import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';

import { BranchEmployerService } from "src/app/core/services/branch-employer.service";
import { ErrorhanderService } from "src/app/core/services/error-handler.service";
import { BranchEmployer } from "src/app/features/branch-employers/models/branch-employers";
import { EmployerDetailsModel } from "src/app/features/branch-employers/models/employer-details-model";
import { UpdateEmployerService } from "src/app/features/branch-employers/update-employer.service";
import { BranchService } from '@lmsrestServices';

import { AppUtilityService } from "src/app/app-utility.service";
import { NgxPaginationModule, PaginationInstance } from 'ngx-pagination';
import { LMSSelectOptionDirective, LMS_NoSpecialsDirective, NumbersOnlyDirective, UpperCaseInputDirective } from '@lmsSharedUI';
import { BranchModel, EmploymentDetailsModel } from '@lmsModels';
import { LmsPaginateComponent } from '@lmsShared';
import { MdbModalModule, MdbModalRef } from 'mdb-angular-ui-kit/modal';

const HQSTAFF = 'HQ Staff';

@Component({
  selector: "app-update-employer",
  templateUrl: "./update-employer.component.html",
  styleUrls: ["./update-employer.component.css"],
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, LMSSelectOptionDirective, UpperCaseInputDirective,
    MdbModalModule, NgxPaginationModule,LmsPaginateComponent, NumbersOnlyDirective, LMS_NoSpecialsDirective]
})
export class UpdateEmployerComponent implements OnInit {
  employerModel = new EmployerDetailsModel();
  employerDetailsForm: FormGroup;

  employerSearchForm = this.fb.group({
    employerName: ['', Validators.required],
    searchBranch: ['', Validators.required],
    brancName: [[]],
  });

  submitted = false;
  lstEmployers: any[] = [];
  userProfile: any;
  canEdit = false;
  employerName = '';
  nameEntered = false;
  dataLoading = false;
  // public lstShiftingRule = SHIFTINGRULES;
  // public lstPayMethod = PAYMETHODS;
  // public lstWageFrequency = WAGEFREQUENCY;
  // public payDates = PAYDATES;
  errorMessages: string[] = [];
  currentStepIndex = 0;
  basicInfoFormSubscription: Subscription;
  formSubmitted = false;
  allFormsValid = false;
  pageTitle = 'Add Branch Employer';
  employers: BranchEmployer[];
  lstBranches: BranchModel[];
  userBranch = '';
  searchInput: any;
  selectedBranch: any;

  config: PaginationInstance = {
    id: 'pager',
    itemsPerPage: 8,
    currentPage: 1,
  };

  employerNametxt = '';
  HQ_STAFF = HQSTAFF;
  constructor(
    public updateEmployerService: UpdateEmployerService,
    public global_utility: AppUtilityService,
    private fb: FormBuilder,
    private branchEmployerService: BranchEmployerService,
    private errorhandlerService: ErrorhanderService,
    private branchService: BranchService ) {

  }

  get branchName() {
    return this.employerSearchForm.get('brancName');
  }

  get selbranch() {
    return this.employerSearchForm.value.brancName;
  }

  setSelectedBranch(newValue: any): void {
    console.log('selected BRanch:', newValue);
  }

  getBranchByName(name: string) {
    const fl = this.lstBranches.filter(
      (projects) => projects.branchSerial === name,
    );

    return fl.length > 0 ? fl[0] : null;
  }

  ngOnInit(): void {
    if (this.global_utility.getGlobalData('branch_Name') == HQSTAFF) {
      this.branchService.getAllFinbondBranchNames().subscribe((branches) => {
        this.lstBranches = branches;

        const homeBranch = this.getBranchByName(
          this.global_utility.getGlobalData('branch_serial'),
        ); // .branches.pipe(map(br => br.filter(fb => fb.branchSerial == )[0] || null));
        this.selectedBranch = homeBranch;
        this.employerSearchForm.controls.searchBranch.patchValue(
          homeBranch!.branchName,
        );
      });
    }

    //for HQ staff pull
    this.userBranch = this.global_utility.getGlobalData('branch_Name');

    this.employerDetailsForm = this.fb.group({
      employerName: [this.employerModel.employerName],
      parentCompany: [this.employerModel.parentCompany],
      workEmail: [this.employerModel.workEmail],
      tel1: [this.employerModel.tel1, [Validators.required]],
      tel2: [this.employerModel.tel2],
      payDate: [this.employerModel.payDate, [Validators.required]],
      shiftingRule: [
        this.employerModel.shiftingRule,
        [Validators.required],
      ],
      paymentMethod: [
        this.employerModel.paymentMethod,
        [Validators.required],
      ],
      wageFrequency: [
        this.employerModel.wageFrequency,
        [Validators.required],
      ],

      workAddressLine1: [
        this.employerModel.workAddressLine1,
        [Validators.required],
      ],
      workAddressLine2: [this.employerModel.workAddressLine2],
      workAddressSuburb: [
        this.employerModel.workAddressSuburb,
        [Validators.required],
      ],
      workAddressTown: [
        this.employerModel.workAddressTown,
        [Validators.required],
      ],
      workAddressAreaCode: [
        this.employerModel.workAddressAreaCode,
        [Validators.required],
      ],

      postalAddressLine1: [this.employerModel.postalAddressLine1],
      postalAddressLine2: [this.employerModel.postalAddressLine2],
      postalAddressTown: [this.employerModel.postalAddressTown],
      postalAddressAreaCode: [this.employerModel.postalAddressAreaCode],
    });

    if (this.userBranch != HQSTAFF) {
      this.employerSearchForm.controls.searchBranch.patchValue(this.userBranch);
      this.findBranchesByName();
      //let homeBranch = this.getBranchByName(this.global_utility.getGlobalData('branch_serial'));// .branches.pipe(map(br => br.filter(fb => fb.branchSerial == )[0] || null));
      //this.selectedBranch = homeBranch;
      //this.branchName?.setValue(this.userBranch);
    }

    if (this.branchEmployerService.preppedforCreate) {
      this.newEmployer();
    }
  }

  findEmployer(forceEdit = false) {
    if (this.employerSearchForm.valid) {
      this.global_utility.startLoading('Refreshing employers..');

      this.canEdit = false;
      this.submitted = false;
      this.nameEntered = true;

      this.employerModel = new EmployerDetailsModel();
      this.employerModel.employerName =
        this.employerSearchForm.value.employerName || '';
      this.employerModel.branchSerial =
        (this.selbranch! as BranchModel).branchSerial ||
        this.selectedBranch.branchSerial;
      this.employerModel.branchName =
        (this.selbranch! as BranchModel).branchName ||
        this.selectedBranch.branchName;

      // if (!this.selectedBranch && this.selectedBranch == '') {
      //   this.employerDetailsModel.branchSerial = this.global_utility.getGlobalData('branch_serial');
      //   this.employerDetailsModel.branchName = this.global_utility.getGlobalData('branch_Name');
      // } else {
      //   this.employerDetailsModel.branchSerial = this.branchModel.branchSerial;
      //   this.employerDetailsModel.branchName = this.branchModel.branchName;
      // }

      this.updateEmployerService
        .findEmployersByName(this.employerModel)
        .subscribe({
          next: (result: any) => {
            // this.handleEmployers(result);

            if (result && Array.isArray(result)) {
              this.lstEmployers = result;

              if (this.lstEmployers.length === 0) {
                this.canEdit = true;
              }
            }

            this.global_utility.stopLoading();
          },
          error: (err) => {
            this.canEdit = false;
            this.submitted = false;
            this.nameEntered = true;

            this.global_utility.warning(
              'Error finding employer: ' +
                `${err.error.error || err.message || ''}`,
              'Employer search fail',
            );
            this.global_utility.stopLoading();
          },
        });
    }
  }

  onWageFrequencyChange(event: any) {
    const numb: any = 7;
    // if (event.target.value === "Monthly") {
    //   this.payDates = PAYDATES;
    //   return;
    // }
    //this.payDates = PAYDAYOFWEEK;
    if (this.employerModel.payDate > numb) {
      this.employerModel.payDate = 1;
    }
  }

  editEmployer(employer: any) {
    this.submitted = false;
    this.lstEmployers = [];

    this.employerModel.employerName =
      employer.name || employer.employerName;
    this.employerNametxt = this.employerModel.employerName;
    this.employerModel.id = employer.id;
    this.employerModel.parentCompany = employer.parentCompany;
    this.employerModel.workEmail = employer.workEmail || employer.email;
    this.employerModel.email = employer.email;
    this.employerModel.tel1 = employer.tel1;
    this.employerModel.tel2 = employer.tel2;
    this.employerModel.payDate = employer.payDate;
    this.employerModel.shiftingRule = employer.luPaydateShiftDesc;
    this.employerModel.paymentMethod = employer.luSalaryMethodDesc;
    this.employerModel.wageFrequency = employer.luEmpPayFrequency;

    // this.employerDetailsModel.addressId = employer.addressId;
    this.employerModel.workAddressLine1 = employer.physicalAddress1;
    this.employerModel.workAddressLine2 = employer.physicalAddress2;
    this.employerModel.workAddressSuburb = employer.physicalSuburb;
    this.employerModel.workAddressTown = employer.physicalTown;
    this.employerModel.workAddressAreaCode = employer.physicalCode;
    // this.employerDetailsModel.workAddressProvince = employer.ph;

    this.employerModel.postalAddressLine1 = employer.postalAddress1;
    this.employerModel.postalAddressLine2 = employer.postalAddress2;
    this.employerModel.postalAddressTown = employer.postalTown;
    this.employerModel.postalAddressAreaCode = employer.postalCode;

    this.employerModel.employerTNR =
      employer.tnumber || employer.employerTNR;
    this.employerModel.branchSerial =
      employer.serial || employer.branchSerial;

    this.employerModel.branchName =
      this.userBranch == HQSTAFF
        ? this.selectedBranch.branchName
        : employer.branchName || this.userBranch;

    this.canEdit = true;
  }

  deleteEmployer(employerId: any) {
    // console.log("employer", employer);
    // this.employerDetailsModel.id = employer.id;
    this.updateEmployerService
      .deleteEmployer(employerId)
      .subscribe((result: any) => {
        console.log('result', result);
        this.canEdit = false;
        this.global_utility.success('', result.message);
      });
  }

  saveEmployer() {
    this.submitted = true;

    if (!this.employerDetailsForm.invalid) {
      this.global_utility.startLoading('Saving employer.');
      if (!this.employerModel.employerTNR) {
        this.employerModel.employerTNR = 0;
      }

      this.employerModel.userName =
        this.global_utility.getGlobalData('userDisplay'); // if brancj model rue
      this.employerModel.employerBranch =
        this.employerModel.branchName;

      this.updateEmployerService
        .saveEmployer(this.employerModel)
        .subscribe({
          next: (result) => {
            this.global_utility.stopLoading();
            if (result) {
              if (result.errorMessage) {
                this.errorhandlerService.handleString(result.errorMessage);
                this.canEdit = true;
                return;
              }

              this.employerName = result.employerName;
              // this.findEmployer();

              this.canEdit = false;
              this.global_utility.success(
                `Employer ${result.name} saved with TNR = ${result.tnumber}!`,
                'Employer saved!',
              );

              if (this.userBranch != HQSTAFF) {
                this.employerSearchForm.controls.employerName.reset();
                this.employerSearchForm.updateValueAndValidity();
              }

              if (
                this.branchEmployerService.preppedforCreate //&&
                //this.dialogRef
              ) {
                this.branchEmployerService.preppedforCreate = false;
                //this.dialogRef!.close(this.mapEmployerToEmployment(result));
                // return this.dialogRef.close(this.employmentDetailsForm.value);
              }
            }
          },
          error: (err) => {
            if (
              (err.error &&
                err.error.type === 'error' &&
                err.statusText === 'Unknown Error') ||
              err.status === 504
            ) {
              // Offline
              this.errorhandlerService.handleOffline(
                'Server Communication to Delfin failed.',
                'Server OFFLINE',
              );
            } else {
              this.global_utility.stopLoading();
              this.editEmployer(err.error);
            }
          },
        });
    }
  }

  cancelEdit() {
    this.canEdit = false;
    if (this.userBranch != HQSTAFF) {
      this.employerSearchForm.controls.employerName.reset();
      this.employerSearchForm.updateValueAndValidity();
    }

    if (this.branchEmployerService.preppedforCreate) {
      //this.dialogRef!.close();
    }
  }

  goforNew() {
    this.global_utility.warning(
      'Something amazing is about to happen',
      'New employer required',
    );
  }

  // saveEmployerDetail(employerDateil: BranchEmployerDetails) {
  //   this.submitted = true;

  //   if (this.employerDetailsForm.valid) {
  //     const quotedlg = this.loaderService.startLoading('Updating employer.');
  //     if (!this.employerDetailsModel?.employerTNR) {
  //       this.employerDetailsModel.employerTNR = 0;
  //     }

  //     this.employerDetailsModel.userName = this.userService.userLoginName;
  //     this.employerDetailsModel.branchSerial = this.userService.userBranchSerial;

  //     this.updateEmployerService.saveEmployer(this.employerDetailsModel).subscribe((result: any) => {
  //       if (result) {
  //         //console.log('result', result);
  //         this.employerName = result.employerName || '';
  //         //this.findEmployer();
  //       }
  //       this.loaderService.stopLoading(quotedlg);
  //       this.canEdit = true;

  //     });
  //   }
  // }

  mapEmployerToEmployment(employer: any): EmploymentDetailsModel {
    const employer2employment = new EmploymentDetailsModel();

    employer2employment.serial = employer.branchSerial || employer.serial;
    employer2employment.employerName = employer.name || employer.employerName;
    employer2employment.parentCompany = employer.parentCompany;
    employer2employment.payDate = employer.payDate;
    employer2employment.shiftingRule = employer.luPaydateShiftDesc;
    employer2employment.branch = employer.employerBranch;
    employer2employment.branchName = employer.branchName;
    employer2employment.company = employer.company;
    employer2employment.wageCalendar = employer.luEmpPayFrequency;
    employer2employment.paymentMethod = employer.luSalaryMethodDesc;
    employer2employment.workAdressLine1 =
      employer.physicalAddressLine1 || employer.physicalAddress1;
    employer2employment.workAdressLine2 =
      employer.physicalAddressLine2 || employer.physicalAddress2;
    employer2employment.workAdressSuburb =
      employer.physicalAddressSuburb || employer.physicalSuburb;
    employer2employment.workAdressTown = employer.physicalTown;
    employer2employment.workAdressAreaCode = employer.physicalCode;
    employer2employment.employerTNR = employer.tnumber;
    employer2employment.lineManagerEmail = employer.email;
    employer2employment.lineManagerContactNumber = employer.tel1;

    return employer2employment;
  }

  newEmployer() {
    this.lstEmployers = [];
    // if (this.branchEmployerService.preppedforCreate) {
    this.editEmployer(this.employerModel);
    //}

    // this.employerDetailsModel ||= new EmployerDetailsModel();
    this.canEdit = true;
  }

  findBranchesByName() {
    //if (this.userService.userBranchName === HQSTAFF) {
    console.log('br =', this.employerSearchForm.value.searchBranch);
    // if (this.employerSearchForm.value.searchBranch == '') {
    //   this.lstBranches;
    // }
    // let filteredBranches = this.lstBranches.filter(br => br.branchName.startsWith(this.employerSearchForm.value.searchBranch!));
    this.branchService
      .getBranchByName(this.employerSearchForm.value.searchBranch)
      .subscribe((result) => {
        //this.loadingService.stopLoading();
        if (!result || Object.keys(result).length == 0) {
          this.global_utility.warning(
            'No branches found with name: ' +
              this.employerSearchForm.value.searchBranch,
            'Empty Search result',
          );
        } else {
          this.lstBranches = result;
          this.selectedBranch = this.lstBranches[0];
        }
      });
    //}
  }

  get validateEmployerInput() {
    return this.employerDetailsForm.controls;
  }

  get validateSearchInput() {
    return this.employerSearchForm.controls;
  }
}
