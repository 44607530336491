<div class="modal-content">
  <div class="modal-header">
    <div class="modal-title">
      <app-page-cardhead style="margin-top: -1.2rem;" [PageName]="'List of Bank Accounts'"
        [PageHeadWidth]="500"></app-page-cardhead>
    </div>
    <button class="btn btn-close" aria-label="close dialog" (click)="dialogRef.close()"></button>
  </div>

  <div class="modal-body">
    <table w500 id="clientHeader" class="table p-1 table-hover table-sm shadow-2-strong rounded-1">
      <caption>Client Details</caption>
      <tbody>
        <tr>
          <td>First names</td>
          <td>{{clientDetails.firstName}}</td>

          <td>Surname</td>
          <td>{{clientDetails.lastName}}</td>
        </tr>

        <tr>
          <td>ID number</td>
          <td>{{clientDetails.idNumber}}</td>

          <td>Cell number</td>
          <td>{{clientDetails.cellNumber}}</td>
        </tr>
      </tbody>
    </table>

    <div class="card">
      <div class="card-body p-1">
        <div class="form-container center-screen">
          <div class="card" style="width: 700px;">
            <table w600 id="banklistDetails" class="table table-striped table-fixed table-sm p-1">
              <thead class="table-center-header" color="primary">
                <tr>
                  <td colspan="5">
                    Emid Bank Accounts List
                  </td>
                </tr>
              </thead>
              <thead>
                <tr>
                  <th>Account Holder</th>
                  <th>Account Number</th>
                  <th>Account Type</th>
                  <th>Bank Name</th>
                  <th>Select</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let bankDet of bankingDetails">
                  <td>{{bankDet.accountHolder}}</td>
                  <td>{{bankDet.accountNumber}}</td>
                  <td>{{bankDet.accountType}}</td>
                  <td>{{bankDet.bank}}</td>
                  <td>
                    <input type="checkbox" style="height: 20px; width: 20px;" (change)="onSelectAccount(bankDet)">
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="center-screen flex-container">
              <button class="btn btn-primary" mdbRipple (click)="close()">Close</button>
              <button class="btn btn-primary" mdbRipple (click)="submitBankDetails()">Submit</button>
              &nbsp;
            </div>
          </div>
        </div>
