<div class="modal-content">
  <!-- User stats -->
  <section>
    <div class="center-screen" #TABLE>
      <table mat-table #table class="table table-striped table-divider-color table-hover table-sm mb-3 caption-top"
        style="width:750px;">

        <!-- <ng-container matColumnDef="position">
                    <th mat-header-cell *matHeaderCellDef> No. </th>
                    <td mat-cell *matCellDef="let element"> {{element.position}} </td>
                </ng-container> -->

        <caption>User performance </caption>
        <thead>
          <tr>
            <th scope="col" align="center" colspan="3">TOTAL LOANS</th>
            <th scope="col" align="center" colspan="2">LOANS FOR NEW CUSTOMERS</th>
          </tr>
          <tr>
            <th scope="col" align="center">User name</th>
            <th scope="col" align="center">Sum of capital</th>
            <th scope="col" align="center">Count of loans</th>
            <th scope="col" align="center">Sum of capital</th>
            <th scope="col" align="center">Count of loans</th>
          </tr>
        </thead>
        <tbody class="table-group-divider table-divider-color">
          <tr *ngFor="let results of lstPerformanceResults">
            <td align="center">{{results.userName}}</td>
            <td align="right">{{results.totCapital | zaRcurrency}}</td>
            <td align="center">{{results.totCnt}}</td>
            <td align="right">{{results.newTotCapital | zaRcurrency}}</td>
            <td align="center">{{results.newTotCnt}}</td>
            <!-- <td>
                    <button mat-raised-button class="btn btn-primary btn-rounded" mdbRipple color="primary" type="button"
                      (click)="loanDetails(loan.id)">Loan Details</button>
                  </td> -->
          </tr>
          <!-- <tr>
                  <td colspan="3" align="center">
                  <button mat-raised-button class="btn btn-primary btn-rounded me-2"
                    color="primary"
                    (click) = "ExportTOExcel()"><i class="fas fa-fw fa-xmarks-lines me-1"></i>
                    Export stats to Excel </button>
                  </td>
                </tr> -->
        </tbody>
      </table>
    </div>
    <div class="my-3">
      <button class="btn btn-primary me-2" (click)="exportLoansToExcel()">Export full
        report to Excel</button>

      <button class="btn btn-primary me-2" type="button" (click)="close()">Close</button>
    </div>
  </section>
</div>
