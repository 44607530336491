import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatRadioModule } from "@angular/material/radio";
import { LmsPaginateComponent } from "@lmsShared";
import { LMSSharedUiModule } from "@lmsSharedUI";
import { MdbModalModule, MdbModalService } from "mdb-angular-ui-kit/modal";
import { NgxPaginationModule } from "ngx-pagination";

import { BasicEmployersSearchComponent } from "./basic-employer-form/basic-employer-form.component";
import { UpdateEmployerComponent } from "./update-employer/update-employer.component";

// export const routes:Routes = [
//   { path: '',pathMatch:'prefix', redirectTo:'updateEmployers' },
//   //{ path: 'add-branch-employer', component: AddContactComponent },
//   { path: 'updateEmployers', component: UpdateEmployerComponent }
//   //{ path: 'edit-branch-employer', component: EditContactComponent },
//   //{ path: 'view-branch-employers', component: ViewContactsComponent },
//   //{ path: 'view-branch-employer', component: ViewContactComponent }
// ];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    LmsPaginateComponent,
    MatRadioModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    MdbModalModule,
    BasicEmployersSearchComponent,
    UpdateEmployerComponent
  ],
  providers:[
    MdbModalService,
  ]
  // exports: [
  //     UpdateEmployerComponent
  // ]
})
export class BranchEmployersModule { }
